import { Badge, Col, Row, Table } from "reactstrap";

import Button from "reactstrap/lib/Button";
import Container from "reactstrap/lib/Container";
import DetailsUserFund from "components/Funds/detailUserFund";
import React from "react";
import { SessionContext } from "contexts/SessionContext";
import { getUserFunds } from "services/fund";

class UserFundsTable extends React.Component {
  state = {
    funds: [],
    showModal: false,
    selectedFund: null,
  };

  async componentDidMount() {
    const resUserFunds = await getUserFunds(this.context.session.id);

    if (resUserFunds.success && resUserFunds.data) {
      this.setState({
        funds: resUserFunds.data,
      });
    }
  }

  switchStatus(fund) {
    switch (fund.status) {
      case 1:
        return (
          <Badge color="" className="badge-dot">
            <i className="bg-yellow" />
            En fondeo
          </Badge>
        );
      case 2:
        return (
          <Badge color="" className="badge-dot">
            <i className="bg-gray" />
            Retirado
          </Badge>
        );
      case 3:
        if (fund.close_request) {
          return (
            <Badge color="" className="badge-dot">
              <i className="bg-warning" />
              Proceso de Cierre
            </Badge>
          );
        } else {
          return (
            <Badge color="" className="badge-dot">
              <i className="bg-success" />
              Activa
            </Badge>
          );
        }
      default:
        return (
          <Badge color="" className="badge-dot">
            <i className="bg-warning" />
            Completada
          </Badge>
        );
    }
  }

  closeModal = async () => {
    const resUserFunds = await getUserFunds(this.context.session.id);

    if (resUserFunds.success && resUserFunds.data) {
      this.setState({
        funds: resUserFunds.data,
        showModal: false,
      });
    } else {
      this.setState({ showModal: false, funds: [] });
    }
  };

  render() {
    return (
      <>
        <DetailsUserFund
          showModal={this.state.showModal}
          fund={this.state.selectedFund}
          closeModal={this.closeModal}
          {...this.props}
          updateDashboard={this.props.updateDashboard}
        />
        {this.state.funds.length ? (
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th scope="col">Invertido</th>
                <th scope="col">Plazo</th>
                <th scope="col">Interés</th>
                <th scope="col">Modalidad</th>
                <th scope="col">Estatus</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {this.state.funds.map((fund) => (
                <tr key={fund.fund_id}>
                  <td>
                    ${fund.invest_amount.replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                  </td>
                  <td>
                    {fund.period ? parseInt(fund.period) + " Meses" : "--"}
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      <span className="mr-2">
                        {fund.interest.replace(/\d(?=(\d{3})+\.)/g, "$&,")}%
                      </span>
                    </div>
                  </td>
                  <td className="">
                    {fund.modality === 1 ? (
                      <React.Fragment key={fund.modality}>
                        <i className="ni ni-app ni-lg text-primary mr-3" />
                        Fija
                      </React.Fragment>
                    ) : (
                      <React.Fragment key={fund.modality}>
                        <i className="ni ni-atom ni-lg text-warning mr-3" />
                        Dinámica
                      </React.Fragment>
                    )}
                  </td>
                  <td className="" key={fund.status}>
                    {this.switchStatus(fund)}
                  </td>
                  <td>
                    <Button
                      color="primary"
                      size="sm"
                      className=""
                      onClick={() =>
                        this.setState({
                          selectedFund: fund,
                          showModal: true,
                        })
                      }
                    >
                      Ver más
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <Container>
            <Row className="justify-content-center text-center">
              <Col>
                <p>
                  No cuentas con Fondos <br />
                  <b>
                    ¡Comienza a invertir en alguno de los fondos que tenemos
                    para ti!
                  </b>
                </p>
              </Col>
            </Row>
            <Row className="justify-content-center text-center mb-5">
              <Col>
                <Button
                  onClick={() => this.props.history.push("/dashboard/funds")}
                  color="primary"
                  type="button"
                >
                  Ver fondos
                </Button>
              </Col>
            </Row>
          </Container>
        )}
      </>
    );
  }
}
UserFundsTable.contextType = SessionContext;

export default UserFundsTable;
