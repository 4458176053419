import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { SessionContext, removeSessionCookie } from "contexts/SessionContext";

import BalanceChart from "components/Charts/BalanceChart";
import Header from "components/Headers/Header.js";
import React from "react";
import moment from "moment";
import UserFundsTable from "components/Funds/userFundsTable";
import { getUserBalance } from "services/userBalance";
import { getUserTransactionHistory } from "services/transactionsHistory";
import UserTransactionsTable from "components/Transactions/userTransactionsTable";

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeNav: 1,
      transactions: [],
      BalanceChartData: "data1",
    };
  }

  async componentDidMount() {
    const resUserBalance = await getUserBalance(this.context.session.id);
    const resUserTransactions = await getUserTransactionHistory(
      this.context.session.id,
      moment(this.context.session.creation_date).format("YYYY-MM-DD"),
      moment().add(1, "d").format("YYYY-MM-DD")
    );

    if (resUserTransactions.success && resUserTransactions.data) {
      this.setState({ transactions: resUserTransactions.data });

      const values = resUserTransactions.data.reduce(
        (content, transaction) => {
          if (transaction.transaction_type === 1) {
            content[0] += parseFloat(transaction.amount);
          } else if (transaction.transaction_type === 2) {
            content[1] += parseFloat(transaction.amount);
          } else if (transaction.transaction_type === 5) {
            content[2] += parseFloat(transaction.amount);
          } else if (transaction.transaction_type === 6) {
            content[3] += parseFloat(transaction.amount);
          }
          return content;
        },
        [0, 0, 0, 0]
      );
      const deposits = values[0].toFixed(2).toString();
      const withdraws = values[1].toFixed(2).toString();
      const interests = values[2].toFixed(2).toString();
      const retentions = values[3].toFixed(2).toString();
      this.setState({
        deposits,
        withdraws,
        interests,
        retentions,
      });
    }

    if (!resUserBalance) {
      removeSessionCookie();
      this.props.history.push("/auth/login");
    } else {
      const creditAvailable = resUserBalance.data.available_amount;
      const creditInvested = resUserBalance.data.invested_amount;
      const creditPendingFund = resUserBalance.data.pending_fund_amount;
      const walletValue = resUserBalance.data.wallet_amount;
      this.setState({
        creditAvailable,
        creditInvested,
        creditPendingFund,
        walletValue,
      });
    }
  }

  updateDashboard = async () => {
    const resUserBalance = await getUserBalance(this.context.session.id);
    const resUserTransactions = await getUserTransactionHistory(
      this.context.session.id,
      moment(this.context.session.creation_date).format("YYYY-MM-DD"),
      moment().add(1, "d").format("YYYY-MM-DD")
    );

    if (resUserTransactions.success && resUserTransactions.data) {
      this.setState({ transactions: resUserTransactions.data });

      const values = resUserTransactions.data.reduce(
        (content, transaction) => {
          if (transaction.transaction_type === 1) {
            content[0] += parseFloat(transaction.amount);
          } else if (transaction.transaction_type === 2) {
            content[1] += parseFloat(transaction.amount);
          } else if (transaction.transaction_type === 5) {
            content[2] += parseFloat(transaction.amount);
          } else if (transaction.transaction_type === 6) {
            content[3] += parseFloat(transaction.amount);
          }
          return content;
        },
        [0, 0, 0, 0]
      );
      const deposits = values[0].toFixed(2).toString();
      const withdraws = values[1].toFixed(2).toString();
      const interests = values[2].toFixed(2).toString();
      const retentions = values[3].toFixed(2).toString();
      this.setState({
        deposits,
        withdraws,
        interests,
        retentions,
      });
    }

    if (!resUserBalance) {
      removeSessionCookie();
      this.props.history.push("/auth/login");
    } else {
      const creditAvailable = resUserBalance.data.available_amount;
      const creditInvested = resUserBalance.data.invested_amount;
      const creditPendingFund = resUserBalance.data.pending_fund_amount;
      const walletValue = resUserBalance.data.wallet_amount;
      this.setState({
        creditAvailable,
        creditInvested,
        creditPendingFund,
        walletValue,
      });
    }
  };

  render() {
    return (
      <>
        {/* Header */}
        <Header
          showStats={true}
          creditAvailable={this.state.creditAvailable}
          creditInvested={this.state.creditInvested}
          creditPendingFund={this.state.creditPendingFund}
          walletValue={this.state.walletValue}
        />

        {/* Page content */}
        <div className="mt--7 mx-3">
          <Row>
            <Col className="mb-5" xs={12} sm={12} md={6}>
              <Card className="shadow">
                <CardHeader className="bg-transparent">
                  <Row className="justify-content-between">
                    <div className="col-auto col-xl-auto mb-3 mb-xl-0 text-center">
                      <h6 className="text-uppercase ls-1 mb-1">Portafolio:</h6>
                      <h5 className="mb-0">
                        $
                        {this.state.walletValue
                          ? this.state.walletValue.replace(
                              /\d(?=(\d{3})+\.)/g,
                              "$&,"
                            )
                          : ""}
                      </h5>
                    </div>
                    <div className="col-auto col-xl-auto mb-3 mb-xl-0 text-center">
                      <h6 className="text-uppercase ls-1 mb-1">Depositado:</h6>
                      <h5 className="mb-0">
                        $
                        {this.state.interests
                          ? this.state.deposits.replace(
                              /\d(?=(\d{3})+\.)/g,
                              "$&,"
                            )
                          : ""}
                      </h5>
                    </div>
                    <div className="col-auto col-xl-auto mb-3 mb-xl-0 text-center">
                      <h6 className="text-uppercase ls-1 mb-1">Intereses:</h6>
                      <h5 className="mb-0">
                        $
                        {this.state.interests
                          ? this.state.interests.replace(
                              /\d(?=(\d{3})+\.)/g,
                              "$&,"
                            )
                          : ""}
                      </h5>
                    </div>
                    <div className="col-auto col-xl-auto mb-3 mb-xl-0 text-center">
                      <h6 className="text-uppercase ls-1 mb-1">Retenciones:</h6>
                      <h5 className="mb-0">
                        $
                        {this.state.retentions
                          ? this.state.retentions.replace(
                              /\d(?=(\d{3})+\.)/g,
                              "$&,"
                            )
                          : ""}
                      </h5>
                    </div>
                    <div className="col-auto col-xl-auto mb-3 mb-xl-0 text-center">
                      <h6 className="text-uppercase ls-1 mb-1">Retirado:</h6>
                      <h5 className="mb-0">
                        $
                        {this.state.interests
                          ? this.state.withdraws.replace(
                              /\d(?=(\d{3})+\.)/g,
                              "$&,"
                            )
                          : ""}
                      </h5>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  {/* Chart */}
                  <BalanceChart />
                </CardBody>
              </Card>
            </Col>
            <Col className="mb-5" xs={12} sm={12} md={6}>
              <Card className="shadow" style={{ maxHeight: "463px" }}>
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Transacciones realizadas</h3>
                    </div>
                  </Row>
                </CardHeader>
                <UserTransactionsTable
                  {...this.props}
                  transactions={this.state.transactions}
                />
              </Card>
            </Col>
          </Row>
          <Row className="mt-md--3">
            <Col className="mb-5" xs={12} sm={12} md={12}>
              <Card className="shadow" style={{ maxHeight: "463px" }}>
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Fondos pertenecientes</h3>
                    </div>
                  </Row>
                </CardHeader>
                <UserFundsTable
                  funds={this.state.funds}
                  {...this.props}
                  updateDashboard={this.updateDashboard}
                />
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}
Index.contextType = SessionContext;

export default Index;
