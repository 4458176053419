import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Input,
  Row,
} from "reactstrap";
import { SessionContext, setSessionCookie } from "contexts/SessionContext";

import React from "react";
import moment from "moment";
import { updateUser } from "services/user";

class CompleteRegister extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errorPhone: false,
      errorCurp: false,
      errorRfc: false,
      errorPostalCode: false,
      errorState: false,
      errorGender: false,
      errorSource: false,
      otherSource: false,
      otherSourceField: "",
      resourcesSource: [],
    };
    this.mexicanStates = [
      "Aguascalientes",
      "Baja California",
      "Baja California Sur",
      "Campeche",
      "Chiapas",
      "Chihuahua",
      "Coahuila de Zaragoza",
      "Colima",
      "Ciudad de México",
      "Durango",
      "Guanajuato",
      "Guerrero",
      "Hidalgo",
      "Jalisco",
      "Estado de Mexico",
      "Michoacan de Ocampo",
      "Morelos",
      "Nayarit",
      "Nuevo Leon",
      "Oaxaca",
      "Puebla",
      "Queretaro",
      "Quintana Roo",
      "San Luis Potosi",
      "Sinaloa",
      "Sonora",
      "Tabasco",
      "Tamaulipas",
      "Tlaxcala",
      "Veracruz de Ignacio de la Llave",
      "Yucatan",
      "Zacatecas",
    ];
  }

  componentDidMount() {
    // Session Validation
    this.context.checkSession();
    console.log(this.context);
    if (this.context.session.status !== 3) {
      this.props.history.push("/auth/login");
    }
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
      errorPhone: false,
      errorCurp: false,
      errorRfc: false,
      errorPostalCode: false,
      errorState: false,
      errorGender: false,
      errorSource: false,
    });
  };

  handleChecks = (event) => {
    let value = this.state.resourcesSource;
    if (!value.includes(event.target.name)) {
      value.push(event.target.name);
    } else {
      value = value.filter((val) => val !== event.target.name);
    }
    this.setState({
      resourcesSource: value,
      errorPhone: false,
      errorCurp: false,
      errorRfc: false,
      errorPostalCode: false,
      errorState: false,
      errorGender: false,
      errorSource: false,
    });
    console.log(this.state);
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    const phone = validPhone(this.state.phone);
    const errorPhone = !phone ? true : false;
    const curp = validCurp(this.state.curp);
    const errorCurp = !curp ? true : false;
    const rfc = validRfc(this.state.rfc);
    const errorRfc = !rfc ? true : false;
    const postal_code = validPostalCode(this.state.postal_code);
    const errorPostalCode = !postal_code ? true : false;
    const state = validateSelect(this.state.state);
    const errorState = !state ? true : false;
    const gender = validateSelect(this.state.gender);
    const errorGender = !gender ? true : false;

    const resourcesSource = validResourceSources(
      this.state.resourcesSource,
      this.state.otherSourceField
    );
    const errorSource = !resourcesSource ? true : false;

    if (
      errorPhone ||
      errorCurp ||
      errorRfc ||
      errorPostalCode ||
      errorState ||
      errorGender ||
      errorSource
    ) {
      this.setState({
        phone,
        curp,
        rfc,
        postal_code,
        state,
        gender,
        errorPhone,
        errorCurp,
        errorRfc,
        errorPostalCode,
        errorState,
        errorGender,
        errorSource,
      });
    } else {
      const user = {
        id: this.context.session.id,
        name: formatText(this.state.name),
        last_name: formatText(this.state.last_name),
        second_last_name: formatText(this.state.second_last_name),
        phone: this.state.phone,
        state: this.state.state,
        city: this.state.city,
        street: this.state.street,
        suburb: this.state.suburb,
        int_number: this.state.int_number,
        ext_number: this.state.ext_number,
        postal_code: this.state.postal_code,
        status: 4,
        birthdate: moment(this.state.birthdate).format("YYYY-MM-DD"),
        curp: this.state.curp.toUpperCase(),
        rfc: this.state.rfc.toUpperCase(),
        gender: this.state.gender,
        occupation: this.state.occupation,
        resources_source: resourcesSource,
        beneficiary_name: formatText(this.state.beneficiary_name),
        beneficiary_email: this.state.beneficiary_email,
      };
      console.log(user);
      const updateUserResponse = await updateUser(user);
      if (!updateUserResponse) this.setState({ apiError: true });
      else {
        if (updateUserResponse.user) {
          await setSessionCookie(updateUserResponse.user);
          this.context.checkSession();
          this.props.history.push("/dashboard");
        }
      }
    }
  };

  render() {
    return (
      <Col lg="12" md="12">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-muted mb-4">
              <h1>Información personal</h1>
            </div>
            <div className="mb-4">
              <p>
                ¡Bienvenido a Inversa! Estás a un paso de poder hacer crecer tu
                dinero de una forma fácil y segura.
                <br />
                Ya solo falta completar tu información personal.
              </p>
            </div>
            <hr />
            <div>
              <Form role="form" onSubmit={this.handleSubmit}>
                <Row>
                  <Col lg="4" sm="12">
                    <FormGroup>
                      <label>Nombre(s) *</label>
                      <Input
                        onChange={this.handleChange}
                        required
                        name="name"
                        type="text"
                        className="form-control-alternative"
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="4" sm="12">
                    <FormGroup>
                      <label>Apellido Paterno *</label>
                      <Input
                        onChange={this.handleChange}
                        required
                        name="last_name"
                        type="text"
                        className="form-control-alternative"
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="4" sm="12">
                    <FormGroup>
                      <label>Apellido Materno *</label>
                      <Input
                        onChange={this.handleChange}
                        required
                        name="second_last_name"
                        type="text"
                        className="form-control-alternative"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col lg="4">
                    <FormGroup>
                      <label>Fecha de nacimiento *</label>
                      <Input
                        onChange={this.handleChange}
                        required
                        name="birthdate"
                        type="date"
                        className="form-control-alternative"
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="4">
                    <FormGroup>
                      <label>
                        Teléfono{" "}
                        <small className="text-muted">(10 dígitos) </small>*
                      </label>
                      <Input
                        onChange={this.handleChange}
                        required
                        name="phone"
                        type="tel"
                        maxLength="10"
                        pattern="[0-9]{10}"
                        className={`form-control-alternative ${
                          this.state.errorPhone &&
                          "is-invalid border border-warning text-warning"
                        }`}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col lg="4">
                    <FormGroup>
                      <label>Genero *</label>
                      <Input
                        onChange={this.handleChange}
                        required
                        name="gender"
                        type="select"
                        defaultValue="default"
                        className={`form-control-alternative ${
                          this.state.errorGender &&
                          "is-invalid border border-warning text-warning"
                        }`}
                      >
                        <option disabled value="default">
                          -- Selecciona una opcion --
                        </option>
                        <option value={1}>Femenino</option>
                        <option value={2}>Masculino</option>
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col lg="4">
                    <FormGroup>
                      <label>CURP *</label>
                      <Input
                        onChange={this.handleChange}
                        required
                        name="curp"
                        type="text"
                        style={{ textTransform: "uppercase" }}
                        className={`form-control-alternative ${
                          this.state.errorCurp &&
                          "is-invalid border border-warning text-warning"
                        }`}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="4">
                    <FormGroup>
                      <label>RFC con homoclave *</label>
                      <Input
                        onChange={this.handleChange}
                        required
                        name="rfc"
                        type="text"
                        style={{ textTransform: "uppercase" }}
                        className={`form-control-alternative ${
                          this.state.errorRfc &&
                          "is-invalid border border-warning text-warning"
                        }`}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col lg="8" sm="12">
                    <FormGroup>
                      <label>Calle *</label>
                      <Input
                        onChange={this.handleChange}
                        required
                        name="street"
                        type="text"
                        className="form-control-alternative"
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="2" xs="6">
                    <FormGroup>
                      <label>No. Ext</label>
                      <Input
                        onChange={this.handleChange}
                        name="ext_number"
                        type="text"
                        className="form-control-alternative"
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="2" xs="6">
                    <FormGroup>
                      <label>No. Int</label>
                      <Input
                        onChange={this.handleChange}
                        name="int_number"
                        type="text"
                        className="form-control-alternative"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col lg="4" sm="12">
                    <FormGroup>
                      <label>Estado *</label>
                      <Input
                        onChange={this.handleChange}
                        required
                        name="state"
                        type="select"
                        defaultValue="default"
                        className={`form-control-alternative ${
                          this.state.errorState &&
                          "is-invalid border border-warning text-warning"
                        }`}
                      >
                        <option disabled value="default">
                          -- Selecciona una opcion --
                        </option>
                        {this.mexicanStates.map((state) => (
                          <option vlaue={state} key={state}>
                            {state}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col lg="4" sm="12">
                    <FormGroup>
                      <label>Colonia *</label>
                      <Input
                        onChange={this.handleChange}
                        required
                        name="suburb"
                        type="text"
                        className="form-control-alternative"
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="4" sm="12">
                    <FormGroup>
                      <label>Alcaldía / Municipio *</label>
                      <Input
                        onChange={this.handleChange}
                        required
                        name="city"
                        type="text"
                        className="form-control-alternative"
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="4" xs="6">
                    <FormGroup>
                      <label>Código Postal *</label>
                      <Input
                        onChange={this.handleChange}
                        required
                        name="postal_code"
                        type="number"
                        pattern="[0-9]{10}"
                        className={`form-control-alternative ${
                          this.state.errorPostalCode &&
                          "is-invalid border border-warning text-warning"
                        }`}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col lg="8" sm="12">
                    <FormGroup>
                      <label>
                        Ocupación / Profesión / Actividad económica *:
                      </label>
                      <Input
                        onChange={this.handleChange}
                        required
                        minLength="8"
                        name="occupation"
                        type="text"
                        className="form-control-alternative"
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="8" sm="12">
                    <FormGroup>
                      <label>
                        ¿Cuál es el origen de los recursos que depositarás en
                        Inversa? *
                      </label>
                      {this.state.errorSource && (
                        <p className="text-warning">
                          Debes seleccionar el origen de los recursos.
                        </p>
                      )}
                      <div
                        className={`${
                          this.state.errorSource && "is-invalid text-warning"
                        }`}
                      >
                        <Row>
                          <Col>
                            <div className="custom-control custom-control-alternative custom-checkbox mb-3">
                              <input
                                className="custom-control-input"
                                id="check1"
                                type="checkbox"
                                name="Ahorros"
                                onChange={this.handleChecks}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="check1"
                              >
                                Ahorros
                              </label>
                            </div>
                          </Col>
                          <Col>
                            <div className="custom-control custom-control-alternative custom-checkbox mb-3">
                              <input
                                className="custom-control-input"
                                id="check2"
                                type="checkbox"
                                name="Negocio"
                                onChange={this.handleChecks}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="check2"
                              >
                                Negocio
                              </label>
                            </div>
                          </Col>
                          <Col>
                            <div className="custom-control custom-control-alternative custom-checkbox mb-3">
                              <input
                                className="custom-control-input"
                                id="check3"
                                type="checkbox"
                                name="Sueldo"
                                onChange={this.handleChecks}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="check3"
                              >
                                Sueldo
                              </label>
                            </div>
                          </Col>
                          <Col>
                            <div className="custom-control custom-control-alternative custom-checkbox mb-3">
                              <input
                                className="custom-control-input"
                                id="check4"
                                type="checkbox"
                                name="Herencia"
                                onChange={this.handleChecks}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="check4"
                              >
                                Herencia
                              </label>
                            </div>
                          </Col>
                        </Row>
                        <div>
                          <div className="custom-control custom-control-alternative custom-checkbox mb-3">
                            <input
                              className="custom-control-input"
                              onChange={() =>
                                this.setState({
                                  otherSource: !this.state.otherSource,
                                  otherSourceField: "",
                                })
                              }
                              id="check5"
                              type="checkbox"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="check5"
                            >
                              Otro (Describir):
                            </label>
                          </div>
                          <Input
                            onChange={this.handleChange}
                            required
                            minLength="8"
                            value={this.state.otherSourceField}
                            name="otherSourceField"
                            type="text"
                            className="form-control-alternative"
                            disabled={!this.state.otherSource}
                          />
                        </div>
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="mb-5">
                  <Col lg="8" sm="12">
                    <FormGroup>
                      <label>Nombre de Beneficiario</label>
                      <Input
                        onChange={this.handleChange}
                        name="beneficiary_name"
                        type="text"
                        className="form-control-alternative"
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="4" sm="12">
                    <FormGroup>
                      <label>Email de Beneficiario</label>
                      <Input
                        onChange={this.handleChange}
                        name="beneficiary_email"
                        type="email"
                        className="form-control-alternative"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col className="text-center">
                    <Button color="primary" type="submit">
                      Continuar
                    </Button>
                  </Col>
                </Row>
                <p className="text-center mt-3 mb--2">
                  Valida que tus datos estén correctos antes de continuar.
                </p>
              </Form>
            </div>
          </CardBody>
        </Card>
      </Col>
    );
  }
}
CompleteRegister.contextType = SessionContext;

function validResourceSources(sources, otherSource) {
  if (otherSource) {
    if (sources.length > 0) {
      return `${otherSource},${sources.join()}`;
    } else {
      return otherSource;
    }
  } else {
    if (sources.length > 0) {
      return sources.join();
    } else return false;
  }
}

function validPostalCode(postalCode) {
  const cleanPostalCode = postalCode.replace(/[^\d]/g, "");
  if (cleanPostalCode.length >= 4 && cleanPostalCode.length <= 5)
    return cleanPostalCode;
  return false;
}

function validPhone(phoneNumber) {
  const cleanPhoneNumber = phoneNumber.replace(/[^\d]/g, "");
  if (cleanPhoneNumber.length === 10) return cleanPhoneNumber;
  return false;
}

function validateSelect(value) {
  if (value !== undefined) return value;
  return false;
}

function validRfc(rfc) {
  if (!rfc) return false;
  // https://es.stackoverflow.com/questions/31713/c%C3%B3mo-validar-un-rfc-de-m%C3%A9xico-y-su-digito-verificador
  rfc = rfc.toUpperCase();
  const regex =
    /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/;
  if (rfc.match(regex)) return rfc;
  return false;
}

function validCurp(curp) {
  if (!curp) return false;
  // https://es.stackoverflow.com/questions/31039/c%C3%B3mo-validar-una-curp-de-m%C3%A9xico
  curp = curp.toUpperCase();
  const regex =
    /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/;
  if (curp.match(regex)) return curp;
  return false;
}

export default CompleteRegister;

function formatText(text) {
  let finalText = "";

  if (text) {
    text = text.trim();
    const textArray = text.split(" ");

    for (let textPart of textArray) {
      textPart =
        textPart.charAt(0).toUpperCase() + textPart.slice(1).toLowerCase();
      finalText += textPart + " ";
    }
    finalText = finalText.trim();
  }

  return finalText;
}
