import { Col, Container, Nav, NavItem, NavLink, Row } from "reactstrap";

import React from "react";

class Login extends React.Component {
  render() {
    return (
      <>
        <footer className="py-5">
          <hr></hr>
          <Container>
            <Row className="align-items-center justify-content-xl-between">
              <Col xl="6">
                <div className="copyright text-center text-xl-left text-muted">
                  © 2021 Inversa Financiera Digital S.A de C.V.
                </div>
              </Col>
              <Col xl="6">
                <Nav className="nav-footer justify-content-center justify-content-xl-end">
                  <NavItem>
                    <NavLink href={`${process.env.REACT_APP_HOST_PAGE}/terminos-condiciones`}>
                      Términos y condiciones
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href={`${process.env.REACT_APP_HOST_PAGE}/aviso-privacidad`}>
                      Aviso de privacidad
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href={`${process.env.REACT_APP_HOST_PAGE}/#faq`}>
                      Preguntas Frecuentes
                    </NavLink>
                  </NavItem>
                </Nav>
              </Col>
            </Row>
          </Container>
        </footer>
      </>
    );
  }
}

export default Login;
