import { Redirect, Route, Switch } from "react-router-dom";

import AdminFooter from "components/Footers/AdminFooter.js";
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import { Container, Spinner } from "reactstrap";
import React from "react";
import { SessionContext } from "contexts/SessionContext";
import Sidebar from "components/Sidebar/Sidebar.js";
import routes from "routes.js";

class Admin extends React.Component {
  state = {
    loading: true,
  };

  async componentDidMount() {
    document.title = "Inversa - Financiera Digital";
    // TODO: reload session data to validate the login information.
    // Session Validation
    this.context.checkSession();
    if (!this.context.session.id) {
      this.props.history.push("/auth/login");
    } else if (this.context.session.status === 3) {
      this.props.history.push("/auth/complete-register");
    } else if (this.context.session.status === 2) {
      this.props.history.push("/auth/verify-account");
    } else if (this.context.session.status === 4) {
      this.props.history.push("/auth/add-user-files");
    } else if (this.context.session.status === 5) {
      this.props.history.push("/auth/accept-contract");
    } else if (this.context.session.status === 6) {
      this.props.history.push("/auth/wait-approval");
    }
    await new Promise((resolve) => setTimeout(resolve, 3000));
    this.setState({ loading: false });
  }

  componentDidUpdate(e) {
    // Session Validation
    this.context.checkSession();
    if (!this.context.session.id) {
      this.props.history.push("/auth/login");
    } else if (this.context.session.status !== 2) {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.mainContent.scrollTop = 0;
    }
  }
  getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/dashboard") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (
        this.props.location.pathname.indexOf(
          routes[i].layout + routes[i].path
        ) !== -1
      ) {
        return routes[i].text;
      }
    }
    return "Brand";
  };
  render() {
    // Session Validation
    if (!this.context.session.id) {
      return null;
    }
    if (this.state.loading) {
      return (
        <div
          style={{
            width: "3rem",
            height: "3rem",
            position: "absolute",
            margin: "auto",
            top: "0",
            bottom: "0",
            left: "0",
            right: "0",
          }}
          className="text-center mx-auto justify-content-center"
        >
          <Spinner style={{ width: "4rem", height: "4rem" }} />
          <p>Cargando</p>
        </div>
      );
    } else {
      return (
        <>
          <Sidebar
            {...this.props}
            routes={routes}
            logo={{
              innerLink: "/dashboard/index",
              imgSrc: require("assets/img/brand/favicon-dark.png"),
              imgAlt: "...",
            }}
          />
          <div className="main-content" ref="mainContent">
            <AdminNavbar
              {...this.props}
              user={this.context.session}
              brandText={this.getBrandText(this.props.location.pathname)}
            />
            <Switch>
              {this.getRoutes(routes)}
              <Redirect from="*" to="/dashboard/index" />
            </Switch>
            <Container fluid>
              <AdminFooter />
            </Container>
          </div>
        </>
      );
    }
  }
}
Admin.contextType = SessionContext;

export default Admin;
