import { SessionContext, getSessionCookie } from "contexts/SessionContext";

import React from "react";

export class SessionProvider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      session: getSessionCookie(),
    };
  }

  checkSession = () => {
    const session = getSessionCookie();
    if (
      this.state.session.id !== session.id ||
      this.state.session.status !== session.status
    )
      this.setState({ session });
  };

  componentDidUpdate() {
    const session = getSessionCookie();
    if (
      this.state.session.id !== session.id ||
      this.state.session.status !== session.status
    )
      this.setState({ session });
  }

  render() {
    return (
      <SessionContext.Provider
        value={{ session: this.state.session, checkSession: this.checkSession }}
      >
        {this.props.children}
      </SessionContext.Provider>
    );
  }
}
