export async function getFunds() {
  return fetch(`${process.env.REACT_APP_API_PATH}/fund`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    credentials: "include",
  })
    .then((res) => {
      if (res.status !== 200) return false;
      const response = res.json();
      return response;
    })
    .then((response) => {
      if (!response) return false;
      if (response.success && response.data) return response;
      return false;
    })
    .catch((error) => {
      console.log(error);
      return false;
    });
}

export async function getUserFunds(userId) {
  return fetch(`${process.env.REACT_APP_API_PATH}/fund/${userId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    credentials: "include",
  })
    .then((res) => {
      if (res.status !== 200) return false;
      const response = res.json();
      return response;
    })
    .then((response) => {
      if (!response) return false;
      if (response.success) return response;
      return false;
    })
    .catch((error) => {
      console.log(error);
      return false;
    });
}

export async function requestCloseFund(user_id, fund_id) {
  return fetch(`${process.env.REACT_APP_API_PATH}/fund/request-close`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    credentials: "include",
    body: JSON.stringify({
      user_id,
      fund_id,
    }),
  })
    .then((res) => {
      const response = res.json();
      return response;
    })
    .then((response) => {
      if (!response) return false;
      return response;
    })
    .catch((error) => {
      console.log(error);
      return false;
    });
}

export async function requestWithdrawFund(user_id, fund_id, amount) {
  return fetch(`${process.env.REACT_APP_API_PATH}/fund/withdraw`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    credentials: "include",
    body: JSON.stringify({
      user_id,
      fund_id,
      amount,
    }),
  })
    .then((res) => {
      const response = res.json();
      return response;
    })
    .then((response) => {
      if (!response) return false;
      return response;
    })
    .catch((error) => {
      console.log(error);
      return false;
    });
}
