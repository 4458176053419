export async function getUserTransactionHistory(userId, startDate, endDate) {
  return fetch(
    `${process.env.REACT_APP_API_PATH}/history?start_date=${startDate}&end_date=${endDate}&user_id=${userId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      credentials: "include",
    }
  )
    .then((res) => {
      if (res.status !== 200) return false;
      const response = res.json();
      return response;
    })
    .then((response) => {
      if (!response) return false;
      if (response.success) return response;
      return false;
    })
    .catch((error) => {
      console.log(error);
      return false;
    });
}

export async function getUserYearBalanceHistory(userId, year) {
  return fetch(
    `${process.env.REACT_APP_API_PATH}/history/year-balance?user_id=${userId}&year=${year}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      credentials: "include",
    }
  )
    .then((res) => {
      if (res.status !== 200) return false;
      const response = res.json();
      return response;
    })
    .then((response) => {
      if (!response) return false;
      if (response.success) return response;
      return false;
    })
    .catch((error) => {
      console.log(error);
      return false;
    });
}
