export async function validateNewEmail(email) {
  return fetch(`${process.env.REACT_APP_API_PATH}/auth/validate-new-email`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({
      email,
    }),
  })
    .then((res) => {
      const response = res.json();
      if (res.status !== 200) return false;
      return response;
    })
    .then((response) => {
      if (response.emailExists) return false;
      if (!response.emailExists) return true;
      return false;
    })
    .catch((error) => {
      console.log(error);
      return false;
    });
}

export async function validateUserBalanceForFund(userId, fundId, investAmount) {
  return fetch(`${process.env.REACT_APP_API_PATH}/validate-balance-fund`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    credentials: "include",
    body: JSON.stringify({
      user_id: parseInt(userId),
      fund_id: parseInt(fundId),
      invest_value: investAmount
    }),
  })
    .then((res) => {
      const response = res.json();
      if (res.status !== 200) return false;
      return response;
    })
    .then((response) => {
      if (!response) return false;
      if (response.success && response.status) return response;
      return false;
    })
    .catch((error) => {
      console.log(error);
      return false;
    });
}
