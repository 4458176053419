import {
  Button,
  Card,
  CardHeader,
  Col,
  Container,
  Row,
  Spinner,
  Table,
} from "reactstrap";

import Header from "components/Headers/Header.js";
import JoinFundModal from "components/Funds/joinFundModal";
import React from "react";
import { getFunds } from "services/fund";
import { getUserBalance } from "services/userBalance";
import { SessionContext } from "contexts/SessionContext";

class Funds extends React.Component {
  state = {
    errorLoading: false,
    loading: true,
    funds: null,
    showFundModal: false,
    selectedFund: null,
    notEnoughBalance: false,
  };

  async componentDidMount() {
    const resBalance = await getUserBalance(this.context.session.id);
    console.log(resBalance);
    if (!resBalance || !resBalance.success || !resBalance.data.wallet_amount) {
      this.setState({ loading: false, errorLoading: true });
    } else {
      if (parseFloat(resBalance.data.wallet_amount) < parseFloat(5000)) {
        this.setState({ notEnoughBalance: true });
      }
      const resFunds = await getFunds();
      await new Promise((resolve) => setTimeout(resolve, 500));

      if (!resFunds || !resFunds.success) {
        this.setState({ loading: false, errorLoading: true });
      } else {
        if (resFunds.data.length >= 1) {
          resFunds.data.sort((a, b) => b.interest - a.interest);
          this.setState({
            funds: resFunds.data,
            loading: false,
            errorLoading: false,
          });
        } else {
          this.setState({
            funds: null,
            loading: false,
            errorLoading: false,
          });
        }
      }
    }
  }

  closeModal = async () => {
    this.setState({ showFundModal: false });
    const resFunds = await getFunds();

    if (!resFunds || !resFunds.success) {
      this.setState({ loading: false, errorLoading: true });
    } else {
      if (resFunds.data.length >= 1) {
        resFunds.data.sort((a, b) => b.interest - a.interest);
        this.setState({
          funds: resFunds.data,
          loading: false,
          errorLoading: false,
        });
      }
    }
  };

  render() {
    return (
      <>
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          {/* Table */}
          <Row>
            <div className=" col">
              {/* Join Fund Modal */}
              <JoinFundModal
                showModal={this.state.showFundModal}
                showNotEnoughBalance={this.state.notEnoughBalance}
                fund={this.state.selectedFund}
                closeModal={this.closeModal}
                {...this.props}
              />
              {/* Funds Table */}
              <Card className=" shadow">
                <CardHeader className="bg-transparent">
                  <Row className="align-items-center">
                    <Col md="9">
                      <h1 className="mb-0">Fondos de Inversión</h1>
                    </Col>
                    {/* <Col md="3" className="text-right">
                      <Form>
                        <FormGroup className="mb-0">
                          <InputGroup className="input-group">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="fas fa-search" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input placeholder="Search" type="text" />
                          </InputGroup>
                        </FormGroup>
                      </Form>
                    </Col> */}
                  </Row>
                </CardHeader>

                {this.state.loading ? (
                  <div className="m-5">
                    <div className="d-flex justify-content-center">
                      <Spinner style={{ width: "3rem", height: "3rem" }} />
                    </div>
                    <div className="d-flex justify-content-center mt-3">
                      <h3>Cargando</h3>
                    </div>
                  </div>
                ) : (
                  <React.Fragment>
                    {this.state.errorLoading ? (
                      <div>
                        <div className="m-5">
                          <div className="d-flex justify-content-center mt-3">
                            <h3>
                              :( Hubo un error al cargar los fondos de
                              inversión.
                            </h3>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <React.Fragment>
                        {this.state.funds ? (
                          <React.Fragment>
                            <Table
                              className="align-items-center table-flush"
                              responsive
                            >
                              <thead className="thead-light">
                                <tr>
                                  <th scope="col">Saldo faltante</th>
                                  <th scope="col">Plazo</th>
                                  <th scope="col">Interes anual</th>
                                  <th scope="col">Personas</th>
                                  <th scope="col">Valor final</th>
                                  <th scope="col">Modalidad</th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.funds.map((fund) => (
                                  <tr
                                    key={fund.id}
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      this.setState({
                                        showFundModal: true,
                                        selectedFund: fund,
                                      })
                                    }
                                  >
                                    <th scope="row">
                                      $
                                      {fund.max_invest_value.replace(
                                        /\d(?=(\d{3})+\.)/g,
                                        "$&,"
                                      )}
                                    </th>
                                    <td>
                                      {fund.period
                                        ? parseInt(fund.period) + " Meses"
                                        : "--"}
                                    </td>
                                    <td>
                                      <div className="d-flex align-items-center">
                                        <span className="mr-2">
                                          {fund.interest.replace(
                                            /\d(?=(\d{3})+\.)/g,
                                            "$&,"
                                          )}
                                          %
                                        </span>
                                      </div>
                                    </td>
                                    <td>
                                      <i className="fa fa-users text-info mr-3" />{" "}
                                      {fund.people_involved}
                                    </td>
                                    <th>
                                      <div className="d-flex align-items-center">
                                        <span className="mr-2">
                                          $
                                          {fund.estimated_value.replace(
                                            /\d(?=(\d{3})+\.)/g,
                                            "$&,"
                                          )}
                                        </span>
                                      </div>
                                    </th>
                                    <td>
                                      {fund.modality === 1 ? (
                                        <React.Fragment>
                                          <i className="ni ni-app ni-lg text-primary mr-3" />
                                          Fija
                                        </React.Fragment>
                                      ) : (
                                        <React.Fragment>
                                          <i className="ni ni-atom ni-lg text-warning mr-3" />
                                          Dinámica
                                        </React.Fragment>
                                      )}
                                    </td>
                                    <td className="text-right">
                                      <Button
                                        color="success"
                                        href="#pablo"
                                        onClick={() =>
                                          this.setState({
                                            showFundModal: true,
                                            selectedFund: fund,
                                          })
                                        }
                                        size="sm"
                                      >
                                        Invertir
                                      </Button>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>

                            {/* <CardFooter>
                              <nav aria-label="Page navigation example">
                                <Pagination
                                  className="pagination justify-content-end"
                                  listClassName="justify-content-end"
                                >
                                  <PaginationItem>
                                    <PaginationLink
                                      href="#pablo"
                                      onClick={(e) => e.preventDefault()}
                                      tabIndex="-1"
                                    >
                                      <i className="fa fa-angle-left" />
                                      <span className="sr-only">Previous</span>
                                    </PaginationLink>
                                  </PaginationItem>
                                  <PaginationItem>
                                    <PaginationLink
                                      href="#pablo"
                                      onClick={(e) => e.preventDefault()}
                                    >
                                      1
                                    </PaginationLink>
                                  </PaginationItem>
                                  <PaginationItem className="active">
                                    <PaginationLink
                                      href="#pablo"
                                      onClick={(e) => e.preventDefault()}
                                    >
                                      2
                                    </PaginationLink>
                                  </PaginationItem>
                                  <PaginationItem>
                                    <PaginationLink
                                      href="#pablo"
                                      onClick={(e) => e.preventDefault()}
                                    >
                                      3
                                    </PaginationLink>
                                  </PaginationItem>
                                  <PaginationItem>
                                    <PaginationLink
                                      href="#pablo"
                                      onClick={(e) => e.preventDefault()}
                                    >
                                      <i className="fa fa-angle-right" />
                                      <span className="sr-only">Next</span>
                                    </PaginationLink>
                                  </PaginationItem>
                                </Pagination>
                              </nav>
                            </CardFooter> */}
                          </React.Fragment>
                        ) : (
                          <div>
                            <div className="m-5">
                              <div className="d-flex justify-content-center mt-3">
                                <h3>
                                  Parece que no tenemos fondos activos por el
                                  momento.
                                </h3>
                              </div>
                            </div>
                          </div>
                        )}
                      </React.Fragment>
                    )}
                  </React.Fragment>
                )}
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}
Funds.contextType = SessionContext;

export default Funds;
