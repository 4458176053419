import { Card, CardBody, CardFooter, Col, Row } from "reactstrap";

import React from "react";
import { SessionContext } from "contexts/SessionContext";

class WaitApproval extends React.Component {
  componentDidMount() {
    // Session Validation
    this.context.checkSession();
    console.log(this.context);
    if (this.context.session.status !== 6) {
      this.props.history.push("/auth/login");
    }
  }

  render() {
    return (
      <>
        <Col lg="5" md="7">
          <Card className="bg-secondary shadow border-0">
            <CardBody className="px-lg-5 py-lg-5 text-center">
              <div className="text-center text-muted mb-5">
                <h1>Validación de cuenta</h1>
              </div>
              <p>
                Estamos validando tus datos.
                <br />
                Pronto recibiras un correo de confirmación para que puedas
                empezar a invertir.
                <br />
                Este proceso puede tardar hasta 12 horas.
                <br />
                <br />
                Si no encuentra el correo electrónico, revise también su carpeta
                de correo no deseado/spam.
              </p>
              <hr />
              <blockquote className="blockquote text-center">
                <p className="mb-0">
                  <small>
                    Con la paciencia y la tranquilidad se logra todo….y algo más
                  </small>
                </p>
                <footer className="blockquote-footer">
                  <small> Benjamin Franklin.</small>
                </footer>
              </blockquote>
            </CardBody>
            <CardFooter>
              <Row>
                <Col className="text-left">
                  <small>¿No has recibido tu confirmación?</small>
                  <br />
                  <small>
                    Envianos un correo a <b>contacto@inversadigital.mx</b>
                  </small>
                </Col>
              </Row>
            </CardFooter>
          </Card>
        </Col>
      </>
    );
  }
}
WaitApproval.contextType = SessionContext;

export default WaitApproval;
