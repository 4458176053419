import { Col, Container, Row } from "reactstrap";
import { Redirect, Route, Switch } from "react-router-dom";

import AuthFooter from "components/Footers/AuthFooter.js";
import AuthNavbar from "components/Navbars/AuthNavbar.js";
import CompleteRegister from "views/CompleteRegister";
import ForgotPassword from "views/ForgotPassword";
import Login from "views/Login";
import React from "react";
import Register from "views/Register";
import RestorePassword from "views/RestorePassword";
import AddUserFiles from "views/AddUserFiles";
import WaitApproval from "views/WaitApproval";
import { SessionContext } from "contexts/SessionContext";
import VerifyAccount from "views/VerifyAccount";
import AcceptContract from "views/AcceptContract";

class Auth extends React.Component {
  componentDidMount() {
    document.title = "Inversa - Financiera Digital";

    // Session Validation
    this.context.checkSession();
    if (this.context.session.id && this.context.session.status === 1) {
      this.props.history.push("/dashboard");
    }
    document.body.classList.add("bg-secondary");
  }
  componentWillUnmount() {
    document.body.classList.remove("bg-secondary");
  }
  render() {
    return (
      <>
        <div className="main-content">
          <AuthNavbar />

          <div className="header bg-gradient-darker py-7 py-lg-8">
            <Container>
              <div className="header-body text-center mb-5">
                <Row className="justify-content-center">
                  <Col lg="5" md="6">
                    {this.context.session.status !== 2 && (
                      <>
                        <h1 className="text-white">¡Bienvenido!</h1>
                        <p className="text-lead text-white">
                          Inicia sesión o crea una cuenta para empezar a hacer
                          crecer tu dinero.
                          <span role="img" aria-label="money-face">
                            {" "}
                            🤑
                          </span>
                        </p>
                      </>
                    )}
                  </Col>
                </Row>
              </div>
            </Container>
            <div className="separator separator-bottom separator-skew zindex-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-secondary"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
          </div>
          {/* Page content */}
          <Container className="mt--8 pb-5">
            <Row className="justify-content-center">
              <Switch>
                <Route
                  path="/auth/verify-account"
                  component={() => <VerifyAccount {...this.props} />}
                />
                <Route
                  path="/auth/complete-register"
                  component={() => <CompleteRegister {...this.props} />}
                />
                <Route
                  path="/auth/add-user-files"
                  component={() => <AddUserFiles {...this.props} />}
                />
                <Route
                  path="/auth/wait-approval"
                  component={() => <WaitApproval {...this.props} />}
                />
                <Route
                  path="/auth/accept-contract"
                  component={() => <AcceptContract {...this.props} />}
                />
                <Route
                  path="/auth/login"
                  component={() => <Login {...this.props} />}
                />
                <Route
                  path="/auth/register"
                  component={() => <Register {...this.props} />}
                />
                <Route
                  path="/auth/forgot"
                  component={() => <ForgotPassword {...this.props} />}
                />
                <Route
                  path="/auth/reset/:token"
                  exact
                  component={(props) => <RestorePassword {...props} />}
                />
                <Redirect from="*" to="/auth/login" />
              </Switch>
            </Row>
          </Container>
        </div>
        <AuthFooter />
      </>
    );
  }
}
Auth.contextType = SessionContext;

export default Auth;
