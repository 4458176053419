export async function getLoggedUser() {
  return fetch(`${process.env.REACT_APP_API_PATH}/user/logged`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    credentials: "include",
  })
    .then((res) => {
      if (res.status !== 200) return false;
      const response = res.json();
      return response;
    })
    .then((response) => {
      if (!response) return false;
      if (response.success && response.user) return response.user;
      return false;
    })
    .catch((error) => {
      console.log(error);
      return false;
    });
}

export async function updateUser(user) {
  return fetch(`${process.env.REACT_APP_API_PATH}/user`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    credentials: "include",
    body: JSON.stringify(user),
  })
    .then((res) => {
      if (res.status !== 200) return false;
      const response = res.json();
      return response;
    })
    .then((response) => {
      if (!response) return false;
      if (response.success && response.user) return response;
      return false;
    })
    .catch((error) => {
      console.log(error);
      return false;
    });
}
