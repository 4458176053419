import { Container, Navbar, NavbarBrand, Nav, Media, Button } from "reactstrap";

import { NavLink } from "react-router-dom";
import React from "react";
import NavItem from "reactstrap/lib/NavItem";

class AdminNavbar extends React.Component {
  constructor(props) {
    super(props);
    let userName = null;
    if (this.props.session?.name) {
      userName = `${this.props.session.name} ${this.props.session.last_name} ${this.props.session.second_last_name}`;
    }
    this.state = {
      userName,
    };
  }

  render() {
    return (
      <>
        <Navbar
          className="navbar-top navbar-horizontal navbar-dark"
          expand="md"
        >
          <Container className="px-4">
            <NavbarBrand href={`${process.env.REACT_APP_HOST_PAGE}`}>
              <img
                alt="..."
                src={require("assets/img/brand/favicon-light.png")}
              />
            </NavbarBrand>
            {this.state.userName && (
              <Nav className="align-items-center d-none d-md-flex" navbar>
                <NavItem>
                  <NavLink to="/dashboard" className="text-white">
                    <Media className="align-items-center">
                      <span className="avatar avatar-sm rounded-circle">
                        <i className="fas fa-user-ninja"></i>
                      </span>
                      <Media className="ml-2 d-none d-lg-block">
                        <span className="mb-0 text-sm font-weight-bold">
                          {this.state.userName}
                        </span>
                      </Media>
                    </Media>
                  </NavLink>
                </NavItem>
              </Nav>
            )}
            {!this.state.userName &&
              window.location.pathname !== "/auth/login" &&
              window.location.pathname !== "/auth/complete-register" &&
              window.location.pathname !== "/auth/add-user-files" &&
              window.location.pathname !== "/auth/accept-contract" &&
              window.location.pathname !== "/auth/wait-approval" &&
              window.location.pathname !== "/auth/register" && (
                <Nav className="align-items-center d-none d-md-flex" navbar>
                  <NavItem className="mr-3">
                    <NavLink to="/auth/login">
                      <Button>Ingresar</Button>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink to="/auth/register">
                      <Button color="primary">Regístrate</Button>
                    </NavLink>
                  </NavItem>
                </Nav>
              )}
          </Container>
        </Navbar>
      </>
    );
  }
}

export default AdminNavbar;
