export async function buyFund(user_id, fund_id, invest_value) {
  return fetch(`${process.env.REACT_APP_API_PATH}/buy-fund`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    credentials: "include",
    body: JSON.stringify({
      user_id,
      fund_id,
      invest_value,
    }),
  })
    .then((res) => {
      const response = res.json();
      return response;
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return false;
    });
}
