import {
  Alert,
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from "reactstrap";
import { SessionContext, setSessionCookie } from "contexts/SessionContext";

import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";
import React from "react";
import { signup } from "services/auth";
import { validateNewEmail } from "services/validations";

class Register extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      confirmPassword: "",
      acceptedTerms: false,
      authError: false,
      errorMessage: "",
      disabledButtons: false,
    };
  }

  handleChange = (event) => {
    this.setState({
      authError: false,
    });
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    this.setState({ authError: false, disabledButtons: true });

    const validEmail = await validateNewEmail(this.state.email);
    if (!validEmail) {
      this.setState({
        authError: true,
        errorMessage: "Email ya registrado",
        disabledButtons: false,
      });
    } else {
      if (this.state.password !== this.state.confirmPassword) {
        this.setState({
          authError: true,
          errorMessage: "Las contraseñas no coinciden",
          disabledButtons: false,
        });
      } else {
        const authResponse = await signup(
          this.state.email,
          this.state.password
        );
        if (!authResponse)
          this.setState({ authError: true, disabledButtons: false });
        else {
          if (authResponse.token && authResponse.user) {
            await setSessionCookie(authResponse.user);
            this.context.checkSession();
            this.props.history.push("/auth/verify-account");
          }
        }
      }
    }
  };

  render() {
    return (
      <>
        <Col lg="6" md="8">
          <Card className="bg-secondary shadow border-0">
            <CardBody className="px-lg-5 py-lg-5">
              <div className="text-center text-muted mb-5">
                <h1>Registrate</h1>
              </div>
              {this.state.authError && (
                <Alert color="danger" className="mt--4 mb-4">
                  <span className="alert-inner--icon">
                    <i className="fa fa-exclamation-circle m-2" />
                  </span>{" "}
                  {this.state.errorMessage}
                </Alert>
              )}
              <Form role="form" onSubmit={this.handleSubmit}>
                <FormGroup>
                  <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      name="email"
                      placeholder="Email"
                      type="email"
                      autoComplete="email"
                      onChange={this.handleChange}
                      disabled={this.state.disabledButtons}
                      required
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      name="password"
                      placeholder="Contraseña"
                      type="password"
                      autoComplete="password"
                      minLength="8"
                      onChange={this.handleChange}
                      disabled={this.state.disabledButtons}
                      required
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      name="confirmPassword"
                      placeholder="Confirmar contraseña"
                      type="password"
                      autoComplete="password"
                      minLength="8"
                      onChange={this.handleChange}
                      disabled={this.state.disabledButtons}
                      required
                    />
                  </InputGroup>
                </FormGroup>
                <Row className="my-4">
                  <Col xs="12">
                    <div className="custom-control custom-control-alternative custom-checkbox">
                      <input
                        name="acceptedTerms"
                        className="custom-control-input"
                        id="customCheckRegister"
                        type="checkbox"
                        onChange={this.handleChange}
                        disabled={this.state.disabledButtons}
                        required
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="customCheckRegister"
                      >
                        <span className="text-muted">
                          Acepto los{" "}
                          <a
                            href={`${process.env.REACT_APP_HOST_PAGE}/terminos-condiciones`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Términos y Condiciones
                          </a>{" "}
                          y{" "}
                          <a
                            href={`${process.env.REACT_APP_HOST_PAGE}/aviso-privacidad`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Aviso de Privacidad
                          </a>
                        </span>
                      </label>
                    </div>
                  </Col>
                </Row>
                <div className="text-center">
                  <Button className="mt-4" color="primary" type="submit">
                    Create account
                  </Button>
                </div>
              </Form>
            </CardBody>
            <CardFooter>
              <Row>
                <Col xs="12">
                  <p className="text-muted text-right mb-0 small">
                    ¿Ya tienes cuenta?{" "}
                    <NavLink to="/auth/login" tag={Link}>
                      Inicia sesión
                    </NavLink>
                  </p>
                </Col>
              </Row>
            </CardFooter>
          </Card>
        </Col>
      </>
    );
  }
}
Register.contextType = SessionContext;

export default Register;
