export async function addFiles(user_id, files) {
  var data = new FormData();
  data.append("user_id", user_id);
  for (let i = 0; i < files.length; i++) {
    data.append("files", files[i]);
  }
  return fetch(`${process.env.REACT_APP_API_PATH}/user-files`, {
    method: "POST",
    headers: {
      Accept: "application/json",
    },
    credentials: "include",
    body: data,
  })
    .then((res) => {
      if (res.status !== 200) return false;
      const response = res.json();
      return response;
    })
    .then((response) => {
      if (response.success) return response;
      return false;
    })
    .catch((error) => {
      console.log(error);
      return false;
    });
}
