import { Col, Row, Table } from "reactstrap";
import Container from "reactstrap/lib/Container";
import React from "react";
import moment from "moment";
import { SessionContext } from "contexts/SessionContext";
import { getUserTransactionHistory } from "services/transactionsHistory";
import { Link } from "react-router-dom";

class UserTransactionsTable extends React.Component {
  state = {
    showModal: false,
    selectedtransaction: null,
  };

  switchTransactionType(transactionType) {
    let type = "";
    switch (transactionType) {
      case 1:
        type = "Deposito de Saldo";
        break;
      case 2:
        type = "Retiro de Saldo";
        break;
      case 3:
        type = "Inversion en fondo";
        break;
      case 4:
        type = "Cierre de fondo";
        break;
      case 5:
        type = "Intereses recibidos";
        break;
      case 6:
        type = "Retención";
        break;
      case 7:
        type = "Retiro de Fondo";
        break;
      default:
        type = "--";
        break;
    }
    return type;
  }

  render() {
    return (
      <>
        {this.props.transactions.length ? (
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th scope="col">Fecha</th>
                <th scope="col">Cantidad</th>
                <th scope="col">Tipo de Transacción</th>
              </tr>
            </thead>
            <tbody>
              {this.props.transactions.map((transaction) => (
                <tr key={transaction.id}>
                  <td>
                    {moment(transaction.creation_date).format(
                      "DD-MM-YYYY, h:mm:ss a"
                    )}
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      <span className="mr-2">
                        $
                        {transaction.amount.replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                      </span>
                    </div>
                  </td>
                  <td>
                    {this.switchTransactionType(transaction.transaction_type)}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <Container>
            <Row className="justify-content-center">
              <Col>
                <p className="text-center">
                  No cuentas con Transacciones realizadas.
                </p>
                <hr></hr>
                <div className="justify-content-start">
                  <h3 className="text-center">
                    Comienza a invertir con nosotros.
                  </h3>
                  <p className="ml-3 mb-4">
                    <b>1.-</b>{" "}
                    <Link to="/dashboard/add-money">
                      Agrega saldo a tu cuenta.
                    </Link>
                    <br></br>
                    <b>2.-</b>{" "}
                    <Link to="/dashboard/funds">
                      Selecciona el fondo de inversion que más te convenga.
                    </Link>
                    <br></br>
                    <b>3.-</b> Espera a que se complete el fondo.
                    <br></br>
                    <br></br>
                    <span className="text-center">
                      <b>¡Listo!</b>
                    </span>
                    <br></br>
                    Veras reflejados tus intereses generados mensualmente de
                    manera automatica en tu 'Saldo disponible'.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        )}
      </>
    );
  }
}
UserTransactionsTable.contextType = SessionContext;

export default UserTransactionsTable;
