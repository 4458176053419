import * as Cookies from "js-cookie";

import React from "react";

const SessionContext = React.createContext(); // Create a context object

const setSessionCookie = (session) => {
  return new Promise((resolve, reject) => {
    Cookies.remove("session");
    let expires = new Date();
    expires.setTime(expires.getTime() + 12 * 60 * 60 * 1000);
    Cookies.set("session", session, { expires });
    resolve(true);
  });
};

const removeSessionCookie = () => {
  Cookies.remove("session");
};

const getSessionCookie = () => {
  const sessionCookie = Cookies.get("session");

  if (sessionCookie) {
    return JSON.parse(sessionCookie);
  } else {
    return {};
  }
};

export {
  SessionContext,
  setSessionCookie,
  getSessionCookie,
  removeSessionCookie,
};
