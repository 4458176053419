import AddMoney from "views/AddMoney.js";
import BankAccounts from "views/BankAccounts";
import Funds from "views/Funds.js";
import Index from "views/Index.js";
import RemoveMoney from "views/RemoveMoney.js";
// import Tables from "views/examples/Tables.js";

var routes = [
  {
    path: "/index",
    name: "Resumen",
    text: "Inicio",
    icon: "fa fa-chart-line text-primary",
    component: Index,
    layout: "/dashboard",
  },
  {
    path: "/funds",
    name: "Fondos",
    text: "Fondos de Inversión",
    icon: "fa fa-boxes text-orange",
    component: Funds,
    layout: "/dashboard",
  },
  {
    path: "/add-money",
    name: "Agregar Saldo",
    text: "Agregar Saldo",
    icon: "fa fa-donate text-success",
    component: AddMoney,
    layout: "/dashboard",
  },
  {
    path: "/remove-money",
    name: "Retirar Saldo",
    text: "Retirar Saldo",
    icon: "fa fa-donate text-red",
    component: RemoveMoney,
    layout: "/dashboard",
  },
  {
    path: "/bank-accounts",
    name: "Cuentas bancarias",
    text: "Cuentas bancarias",
    icon: "fa fa-credit-card text-teal",
    component: BankAccounts,
    layout: "/dashboard",
  },
];
export default routes;
