import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";

import AdminLayout from "layouts/Admin";
import AuthLayout from "layouts/Auth";
import React from "react";
import { SessionProvider } from "providers/SessionProvider";

export const App = () => (
  <div className="App">
    <Routes />
  </div>
);

const Routes = () => {
  return (
    <SessionProvider>
      <BrowserRouter>
        <Switch>
          <Route
            path="/dashboard"
            render={(props) => <AdminLayout {...props} />}
          />
          <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
          <Redirect from="/" to="/dashboard" />
        </Switch>
      </BrowserRouter>
    </SessionProvider>
  );
};
