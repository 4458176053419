import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Form,
  FormGroup,
  Input,
  Row,
} from "reactstrap";

import Alert from "reactstrap/lib/Alert";
import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";
import React from "react";
import { SessionContext } from "contexts/SessionContext";
import { restorePassword } from "services/auth";

class RestorePassword extends React.Component {
  state = {
    password: "",
    confirmPassword: "",
    passwordError: false,
    sentRestart: false,
    tokenError: false,
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
      passwordError: false,
    });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    if (this.state.password !== this.state.confirmPassword) {
      this.setState({ sentRestart: false, passwordError: true });
    } else {
      const token = this.props.match.params.token;
      const response = await restorePassword(this.state.password, token);
      if (!response) {
        this.setState({
          sentRestart: false,
          passwordError: false,
          tokenError: true,
        });
      } else if (response.success) {
        this.setState({
          sentRestart: true,
          passwordError: false,
        });
      }
    }
  };

  render() {
    return (
      <>
        <Col lg="5" md="7">
          <Card className="bg-secondary shadow border-0">
            <CardBody className="px-lg-5 py-lg-5 text-center">
              <div className="text-center text-muted mb-5">
                <h1>Restaurar contraseña.</h1>
              </div>
              {this.state.tokenError && (
                <Alert color="danger" className="mt4 mb-4">
                  <span className="alert-inner--icon">
                    <i className="fa fa-exclamation-circle m-2" />
                  </span>{" "}
                  Este enlace para cambio de contraseña ya se encuentra
                  caducado.
                </Alert>
              )}
              {!this.state.sentRestart ? (
                <>
                  <p>Ingresa tu nueva contraseña.</p>
                  {this.state.passwordError && (
                    <Alert color="danger" className="mt4 mb-4">
                      <span className="alert-inner--icon">
                        <i className="fa fa-exclamation-circle m-2" />
                      </span>{" "}
                      Las contraseñas no coinciden.
                    </Alert>
                  )}
                  <div className="text-center">
                    <Form role="form" onSubmit={this.handleSubmit}>
                      <FormGroup>
                        <Input
                          className="form-control-alternative"
                          placeholder="Contraseña"
                          type="password"
                          autoComplete="new-password"
                          name="password"
                          minLength="8"
                          value={this.state.password}
                          onChange={this.handleChange}
                          required
                        />
                      </FormGroup>
                      <FormGroup>
                        <Input
                          className="form-control-alternative"
                          placeholder="Confirmar contraseña"
                          type="password"
                          name="confirmPassword"
                          minLength="8"
                          value={this.state.confirmPassword}
                          onChange={this.handleChange}
                          required
                        />
                      </FormGroup>
                      <Button className="mt-4" color="primary" type="submit">
                        Continuar
                      </Button>
                    </Form>
                  </div>
                </>
              ) : (
                <>
                  <p>Tu contraseña fue restaurada de manera exitosa.</p>
                  <Button
                    className="mt-4"
                    color="primary"
                    type="button"
                    onClick={() => this.props.history.push("/auth/login")}
                  >
                    Continuar
                  </Button>
                </>
              )}
            </CardBody>
            <CardFooter>
              <Row>
                <Col className="text-left" xs="6">
                  <p className="text-muted mb-0 small">
                    <NavLink to="/auth/login" tag={Link}>
                      Iniciar sesión
                    </NavLink>
                  </p>
                </Col>
                <Col className="text-right" xs="6">
                  <p className="text-muted mb-0 small">
                    <NavLink to="/auth/register" tag={Link}>
                      Crear una nueva cuenta.
                    </NavLink>
                  </p>
                </Col>
              </Row>
            </CardFooter>
          </Card>
        </Col>
      </>
    );
  }
}
RestorePassword.contextType = SessionContext;

export default RestorePassword;
