export async function login(email, password) {
  return fetch(`${process.env.REACT_APP_API_PATH}/auth/login`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    credentials: "include",
    body: JSON.stringify({
      email,
      password,
    }),
  })
    .then((res) => {
      if (res.status !== 200) return false;
      const response = res.json();
      return response;
    })
    .then((response) => {
      if (response.success) return response;
      return false;
    })
    .catch((error) => {
      console.log(error);
      return false;
    });
}

export async function signup(email, password, status = 2) {
  return fetch(`${process.env.REACT_APP_API_PATH}/auth/signup`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    credentials: "include",
    body: JSON.stringify({
      email,
      password,
      status,
    }),
  })
    .then((res) => {
      if (res.status !== 200) return false;
      const response = res.json();
      return response;
    })
    .then((response) => {
      if (response.success) return response;
      return false;
    })
    .catch((error) => {
      console.log(error);
      return false;
    });
}

export async function sendValidateEmail(userId) {
  return fetch(
    `${process.env.REACT_APP_API_PATH}/auth/send-verify-email/${userId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      credentials: "include",
    }
  )
    .then((res) => {
      if (res.status !== 200) return false;
      const response = res.json();
      return response;
    })
    .then((response) => {
      if (response.success) return response;
      return false;
    })
    .catch((error) => {
      console.log(error);
      return false;
    });
}

export async function sendRestorePasswordEmail(email) {
  return fetch(`${process.env.REACT_APP_API_PATH}/auth/send-restore-email`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    credentials: "include",
    body: JSON.stringify({
      email,
    }),
  })
    .then((res) => {
      if (res.status !== 200) return false;
      const response = res.json();
      return response;
    })
    .then((response) => {
      if (response.success) return response;
      return false;
    })
    .catch((error) => {
      console.log(error);
      return false;
    });
}

export async function restorePassword(password, token) {
  return fetch(`${process.env.REACT_APP_API_PATH}/auth/restore-password`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    credentials: "include",
    body: JSON.stringify({
      password,
      token,
    }),
  })
    .then((res) => {
      if (res.status !== 200) return false;
      const response = res.json();
      return response;
    })
    .then((response) => {
      if (response.success) return response;
      return false;
    })
    .catch((error) => {
      console.log(error);
      return false;
    });
}

export async function requestUserApproval(userId) {
  return fetch(`${process.env.REACT_APP_API_PATH}/auth/request-user-approval`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    credentials: "include",
    body: JSON.stringify({
      user_id: userId,
    }),
  })
    .then((res) => {
      if (res.status !== 200) return false;
      const response = res.json();
      return response;
    })
    .then((response) => {
      if (response.success) return response;
      return false;
    })
    .catch((error) => {
      console.log(error);
      return false;
    });
}
