import {
  Container,
  DropdownToggle,
  Media,
  Nav,
  Navbar,
  UncontrolledDropdown,
} from "reactstrap";

import React from "react";

class AdminNavbar extends React.Component {
  constructor(props) {
    super(props);
    const userName = `${this.props.user.name}
    ${this.props.user.last_name}
    ${this.props.user.second_last_name}`;
    this.state = {
      userName,
    };
  }

  render() {
    return (
      <>
        <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
          <Container fluid>
            <p className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block">
              {this.props.brandText}
            </p>
            <Nav className="align-items-center d-none d-md-flex" navbar>
              <UncontrolledDropdown nav>
                <DropdownToggle className="pr-0" nav>
                  <Media className="align-items-center">
                    <span className="avatar avatar-sm rounded-circle">
                      <i className="fas fa-user-ninja"></i>
                    </span>
                    <Media className="ml-2 d-none d-lg-block">
                      <span className="mb-0 text-sm font-weight-bold">
                        {this.state.userName}
                      </span>
                    </Media>
                  </Media>
                </DropdownToggle>
                {/* <DropdownMenu className="dropdown-menu-arrow" right>
                  <DropdownItem to="/dashboard/user-profile" tag={Link}>
                    <i className="ni ni-single-02" />
                    <span>Mi perfil</span>
                  </DropdownItem>
                  <DropdownItem to="/dashboard/user-profile" tag={Link}>
                    <i className="ni ni-settings-gear-65" />
                    <span>Configuración</span>
                  </DropdownItem>
                  <DropdownItem to="/dashboard/user-profile" tag={Link}>
                    <i className="ni ni-calendar-grid-58" />
                    <span>Actividad</span>
                  </DropdownItem>
                  <DropdownItem to="/dashboard/user-profile" tag={Link}>
                    <i className="ni ni-support-16" />
                    <span>Soporte</span>
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem
                    href="/auth/login"
                    onClick={() => removeSessionCookie()}
                  >
                    <i className="fa fa-sign-out-alt" />
                    <span>Cerrar sesión</span>
                  </DropdownItem>
                </DropdownMenu> */}
              </UncontrolledDropdown>
            </Nav>
          </Container>
        </Navbar>
      </>
    );
  }
}

export default AdminNavbar;
