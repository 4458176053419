import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Label,
  Input,
  Form,
  Col,
  Row,
  FormText,
  Spinner,
} from "reactstrap";
import React from "react";
import { SessionContext, setSessionCookie } from "contexts/SessionContext";
import { addFiles } from "services/userFiles";
import { updateUser } from "services/user";

class AddUserFiles extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ine_frontal: null,
      ine_rear: null,
      proof_of_address: null,
      ine_frontal_img: null,
      ine_rear_img: null,
      proof_of_address_img: null,
      loading: false,
    };
  }
  componentDidMount() {
    // Session Validation
    this.context.checkSession();
    console.log(this.context);
    if (this.context.session.status !== 4) {
      this.props.history.push("/auth/login");
    }
  }

  handleChange = (event) => {
    // Validate File Size lower than 4MB.
    let file = false;
    if (event.target.files[0]) {
      // if ((event.target.files[0].size / 1000000).toFixed(2) <= 40) {
        // Create new file with the correct name.
        const blob = event.target.files[0];
        file = new File(
          [blob],
          `${event.target.name}.${blob.name.split(".").pop()}`,
          {
            type: blob.type,
          }
        );
      // }
    } else {
      file = null;
    }
    this.setState({
      [event.target.name]: file,
      [event.target.name + "_img"]: file
        ? URL.createObjectURL(event.target.files[0])
        : null,
    });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    this.setState({ loading: true });

    const files = [
      this.state.ine_frontal,
      this.state.ine_rear,
      this.state.proof_of_address,
    ];
    const addFilesResponse = await addFiles(this.context.session.id, files);
    if (addFilesResponse.success) {
      const updateUserResponse = await updateUser({
        id: this.context.session.id,
        status: 5,
      });
      if (updateUserResponse.user) {
        await setSessionCookie(updateUserResponse.user);
        this.context.checkSession();
        this.props.history.push("/dashboard");
      }
    }
  };

  render() {
    return (
      <Col lg="12" md="12">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-muted mb-4">
              <h1>Información personal</h1>
            </div>
            <div className="mb-4">
              <p>
                ¡Bienvenido a Inversa! Estás a un paso de poder hacer crecer tu
                dinero de una forma fácil y segura.
                <br />
                Ya solo falta completar tu información personal.
              </p>
            </div>
            <hr />
            <div>
              <Form role="form" onSubmit={this.handleSubmit}>
                <Row>
                  <Col lg="6" sm="12">
                    <FormGroup>
                      <Label for="ine_frontal">
                        <h2>
                          <i className="fas fa-id-card mr-2"></i> Imagen de la
                          parte <b>frontal</b> de tu <b>INE</b>
                        </h2>
                      </Label>
                      <Input
                        type="file"
                        name="ine_frontal"
                        id="ine_frontal"
                        accept="image/jpg, image/png, image/jpeg, application/pdf, application/vnd.ms-excel"
                        onChange={this.handleChange}
                        className={
                          this.state.ine_frontal === false
                            ? "border border-warning rounded p-1"
                            : ""
                        }
                      />
                      <FormText
                        color={
                          this.state.ine_frontal === false ? "warning" : "muted"
                        }
                      >
                        Formatos aceptados .jpg .jpeg .png .pdf <br />
                        Peso máximo 4 MB.
                      </FormText>
                    </FormGroup>
                    <div>
                      {this.state.ine_frontal?.type === "application/pdf" ? (
                        <embed
                          src={this.state.ine_frontal_img}
                          width="200"
                          height="auto"
                        ></embed>
                      ) : (
                        <img
                          src={this.state.ine_frontal_img}
                          width="200"
                          height="auto"
                          className="mb-3"
                          alt="ine_frontal_img"
                          hidden={!this.state.ine_frontal_img}
                        />
                      )}
                    </div>
                  </Col>
                  <Col lg="6" sm="12">
                    <FormGroup>
                      <Label for="ine_rear">
                        <h2>
                          <i className="fas fa-money-check mr-2"></i> Imagen de
                          la parte <b>trasera</b> de tu <b>INE</b>
                        </h2>
                      </Label>
                      <Input
                        type="file"
                        name="ine_rear"
                        id="ine_rear"
                        accept="image/jpg, image/png, image/jpeg, application/pdf, application/vnd.ms-excel"
                        onChange={this.handleChange}
                        className={
                          this.state.ine_rear === false
                            ? "border border-warning rounded p-1"
                            : ""
                        }
                      />
                      <FormText
                        color={
                          this.state.ine_rear === false ? "warning" : "muted"
                        }
                      >
                        Formatos aceptados .jpg .jpeg .png .pdf <br />
                        Peso máximo 4 MB.
                      </FormText>
                    </FormGroup>
                    <div>
                      {this.state.ine_rear?.type === "application/pdf" ? (
                        <embed
                          src={this.state.ine_rear_img}
                          width="200"
                          height="auto"
                        ></embed>
                      ) : (
                        <img
                          src={this.state.ine_rear_img}
                          width="200"
                          height="auto"
                          className="mb-3"
                          alt="ine_rear_img"
                          hidden={!this.state.ine_rear_img}
                        />
                      )}
                    </div>
                  </Col>
                </Row>
                <hr />
                <Row className="mt-5">
                  <Col lg="12" sm="12">
                    <FormGroup>
                      <Label for="proof_of_address">
                        <h2>
                          <i className="fas fa-map-marked-alt mr-2"></i>{" "}
                          Comprobante de domicilio.{" "}
                          <em>
                            <small>(No mayor a 3 meses)</small>
                          </em>
                        </h2>
                      </Label>
                      <Input
                        type="file"
                        name="proof_of_address"
                        id="proof_of_address"
                        accept="image/jpg, image/png, image/jpeg, application/pdf, application/vnd.ms-excel"
                        onChange={this.handleChange}
                        className={
                          this.state.proof_of_address === false
                            ? "border border-warning rounded p-1"
                            : ""
                        }
                      />
                      <FormText
                        color={
                          this.state.proof_of_address === false
                            ? "warning"
                            : "muted"
                        }
                      >
                        Formatos aceptados .jpg .jpeg .png .pdf <br />
                        Peso máximo 4 MB.
                      </FormText>
                    </FormGroup>
                    <div>
                      {this.state.proof_of_address?.type ===
                      "application/pdf" ? (
                        <embed
                          src={this.state.proof_of_address_img}
                          width="200"
                          height="auto"
                        ></embed>
                      ) : (
                        <img
                          src={this.state.proof_of_address_img}
                          width="200"
                          height="auto"
                          className="mb-3"
                          alt="proof_of_address_img"
                          hidden={!this.state.proof_of_address_img}
                        />
                      )}
                    </div>
                  </Col>
                </Row>
                <Row className="mt-5">
                  <Col className="text-center">
                    {this.state.loading ? (
                      <Spinner color="dark" />
                    ) : (
                      <Button
                        color="primary"
                        type="submit"
                        disabled={
                          !this.state.ine_frontal ||
                          !this.state.ine_rear ||
                          !this.state.proof_of_address
                        }
                      >
                        Continuar
                      </Button>
                    )}
                  </Col>
                </Row>
                <p className="text-center mt-3 mb--2">
                  Valida que tus datos estén correctos antes de continuar.
                </p>
              </Form>
            </div>
          </CardBody>
        </Card>
      </Col>
    );
  }
}
AddUserFiles.contextType = SessionContext;
export default AddUserFiles;
