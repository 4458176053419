import {
  Alert,
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Modal,
  Row,
  FormText,
} from "reactstrap";

import CurrencyInput from "react-currency-input-field";
import React from "react";
import { SessionContext } from "contexts/SessionContext";
import { buyFund } from "services/buyFund";
import { validateUserBalanceForFund } from "services/validations";

class JoinFundModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      investAmount: props.fund?.min_invest_amount | 0,
      amountError: null,
      amountDetail: null,
      showConfirmation: false,
      showNotEnoughBalance: false,
      success: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      this.setState({
        showModal: this.props.showModal,
        showNotEnoughBalance: this.props.showNotEnoughBalance,
        investAmount: this.props.fund?.min_invest_amount | 0,
        amountDetail: null,
        amountError: null,
      });
    }
  }

  closeModal = () => {
    if (this.state.showConfirmation) {
      this.setState({ showConfirmation: false });
    } else {
      this.setState({
        showConfirmation: false,
        showModal: !this.state.showModal,
      });
    }
  };

  closeSuccess = () => {
    this.setState({ success: false });
    this.props.closeModal();
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    let investAmount = this.state.investAmount;
    if (!this.state.amountDetail) {
      if (investAmount === "undefined" || !investAmount)
        investAmount = this.props.fund?.min_invest_amount;

      const resValidate = await validateUserBalanceForFund(
        this.context.session.id,
        this.props.fund.id,
        investAmount
      );

      if (!resValidate || !resValidate.success) {
        this.setState({ loading: false, errorLoading: true });
      } else {
        switch (resValidate.status) {
          case "SUCCESS":
            this.setState({
              amountError: null,
              showConfirmation: true,
              investAmount,
            });
            break;
          case "VALUE_GREATER_THAN_MAX_FUND_AMOUNT":
            this.setState({
              amountError:
                "El máximo a invertir en este fondo es de: $" +
                this.props.fund.max_invest_value.replace(
                  /\d(?=(\d{3})+\.)/g,
                  "$&,"
                ),
            });
            break;
          case "VALUE_LESS_THAN_MIN_FUND_AMOUNT":
            this.setState({
              amountError:
                "El mínimo a invertir en este fondo es de: $" +
                this.props.fund.min_invest_amount.replace(
                  /\d(?=(\d{3})+\.)/g,
                  "$&,"
                ),
            });
            break;
          case "NOT_ENOUGH_BALANCE":
            this.setState({
              amountError: "No cuentas con el saldo suficiente.",
            });
            break;
          default:
            this.setState({
              amountError: "Ocurrió un error al procesar la inversión.",
            });
            break;
        }
      }
    }
  };

  handleFocus = (event) => event.target.select();

  fundModal = () => (
    <>
      <div className="modal-header bg-default modal-default">
        <h2 className="modal-title" id="modal-title-default">
          Unirse al Fondo de inversión
        </h2>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={this.closeModal}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>

      {this.props.fund && (
        <div className="modal-body bg-secondary rounded pb-0">
          <Container>
            <Row>
              <Col className="my-auto" lg="7">
                <p>
                  <strong>Plazo:</strong>
                </p>
              </Col>
              <Col className="mb-2">
                <div className="border rounded shadow text-center">
                  <p className="m-0">
                    {this.props.fund.period
                      ? parseInt(this.props.fund.period) + " Meses"
                      : "--"}
                  </p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="my-auto" lg="7">
                <p>
                  <strong>Interes Anual:</strong>
                </p>
              </Col>
              <Col className="mb-2">
                <div className="border rounded shadow text-center">
                  <p className="m-0">{this.props.fund.interest}%</p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="my-auto" lg="7">
                <p>
                  <strong>Monto Faltante del fondo:</strong>
                </p>
              </Col>
              <Col className="mb-2">
                <div className="border rounded shadow text-center">
                  <p className="m-0">
                    $
                    {this.props.fund.max_invest_value.replace(
                      /\d(?=(\d{3})+\.)/g,
                      "$&,"
                    )}
                  </p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="my-auto" lg="7">
                <p>
                  <strong>Monto Final del fondo:</strong>
                </p>
              </Col>
              <Col className="mb-2">
                <div className="border rounded shadow text-center">
                  <p className="m-0">
                    $
                    {this.props.fund.estimated_value.replace(
                      /\d(?=(\d{3})+\.)/g,
                      "$&,"
                    )}
                  </p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="my-auto" lg="7">
                <p>
                  <strong>Modalidad:</strong>
                </p>
              </Col>
              <Col className="mb-2">
                <div className="border rounded shadow text-center">
                  <p className="m-0">
                    {this.props.fund.modality === 1 ? "Fija" : "Dinámica"}
                  </p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="my-auto" lg="7">
                <p>
                  <strong>Personas dentro del fondo:</strong>
                </p>
              </Col>
              <Col className="mb-2">
                <div className="border rounded shadow text-center">
                  <p className="m-0">{this.props.fund.people_involved}</p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="my-auto" lg="7">
                <p>
                  <strong>Monto minimo para participar:</strong>
                </p>
              </Col>
              <Col>
                <div className="border rounded shadow text-center">
                  <p className="m-0">
                    $
                    {this.props.fund.min_invest_amount.replace(
                      /\d(?=(\d{3})+\.)/g,
                      "$&,"
                    )}
                  </p>
                </div>
              </Col>
            </Row>

            {this.props.fund.modality === 2 && (
              <Row className="mt-3">
                <Col>
                  <Alert className="alert-default">
                    <strong>Fondo Dinámico:</strong> Al participar en un fondo
                    dinámico, recibes un interés más alto y después de 15 días
                    de haber iniciado el fondo, se podrá finalizar cuando usted
                    o Inversa lo decidan.
                  </Alert>
                </Col>
              </Row>
            )}
            <hr className="mt-3" />
            <Row className="pb-3 pt-2">
              <Col md="6" sm="12" className="pb-2 mt--4">
                <Form role="form">
                  <FormGroup className="mb-1">
                    <label
                      className="form-control-label text-muted"
                      htmlFor="invest-amount"
                    >
                      Monto a invertir
                    </label>
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="fa fa-dollar-sign" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <CurrencyInput
                        onFocus={this.handleFocus}
                        className="form-control"
                        placeholder={parseInt(this.state.investAmount)}
                        defaultValue={parseInt(this.state.investAmount)}
                        allowDecimals={false}
                        allowNegativeValue={false}
                        onValueChange={(value) => {
                          console.log(value);

                          if (value % 10 === 0) {
                            this.setState({
                              amountDetail: "",
                              investAmount: value,
                            });
                          } else {
                            this.setState({
                              amountDetail:
                                "Solo se aceptan inversiones con múltiplos de 10",
                              investAmount: value,
                            });
                          }
                        }}
                      />
                    </InputGroup>
                  </FormGroup>
                </Form>
              </Col>
              <Col md="6" sm="12" className="pb-2 my-auto">
                <Button
                  className="float-right"
                  color="primary"
                  data-dismiss="modal"
                  type="button"
                  onClick={this.handleSubmit}
                >
                  Invertir
                </Button>
              </Col>
              <Col sm="12">
                {this.state.amountDetail && (
                  <FormText color="danger">{this.state.amountDetail}</FormText>
                )}
              </Col>
            </Row>
            {this.state.amountError && (
              <Row className="pb-3 pt--2">
                <Col>
                  <Alert className="alert-danger">
                    {this.state.amountError}
                  </Alert>
                </Col>
              </Row>
            )}
            {this.props.fund.modality === 2 && (
              <Row className="mb-3">
                <Container>
                  <footer className="blockquote-footer">
                    <small>
                      Los fondos Dinámicos pueden ser cerrados después de 15
                      días de haberse iniciado.
                    </small>
                  </footer>
                  <footer className="blockquote-footer">
                    <small>
                      Al cerrar un fondo Dinámico, se te regresa tu monto de
                      inversión y los rendimientos generados hasta esa fecha.
                      Una vez cerrado el fondo, tu saldo se vera reflejado
                      dentro de 48 a 96 horas.
                    </small>
                  </footer>
                </Container>
              </Row>
            )}
          </Container>
        </div>
      )}
    </>
  );

  confirmModal = () => (
    <>
      <div className="modal-header bg-default modal-default">
        <h2 className="modal-title" id="modal-title-default">
          Confirmar inversión
        </h2>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={this.closeModal}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body bg-secondary rounded pb-0">
        <Container>
          <Row>
            <Col>
              <h3>
                Se va a realizar una transacción con la siguiente información.
              </h3>
            </Col>
          </Row>
          <hr className="mt-1"></hr>
          <Row>
            <Col className="my-auto" lg="7">
              <p>
                <strong>Plazo: </strong>
              </p>
            </Col>
            <Col className="mb-2">
              <div className="border rounded shadow text-center">
                <p className="m-0">
                  {this.props.fund.period
                    ? parseInt(this.props.fund.period) + " Meses"
                    : "--"}
                </p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="my-auto" lg="7">
              <p>
                <strong>Interes Anual:</strong>
              </p>
            </Col>
            <Col className="mb-2">
              <div className="border rounded shadow text-center">
                <p className="m-0">{this.props.fund.interest}%</p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="my-auto" lg="7">
              <p>
                <strong>Modalidad:</strong>
              </p>
            </Col>
            <Col className="mb-2">
              <div className="border rounded shadow text-center">
                <p className="m-0">
                  {this.props.fund.modality === 1 ? "Fija" : "Dinámica"}
                </p>
              </div>
            </Col>
          </Row>

          <Row>
            <Col className="my-auto" lg="7">
              <p>
                <strong>Importe a invertir: </strong>
              </p>
            </Col>
            <Col className="mb-2">
              <div className="border rounded shadow text-center">
                <p className="m-0">
                  $
                  {parseFloat(this.state.investAmount)
                    .toFixed(2)
                    .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                </p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="my-auto" lg="7">
              <p>
                <strong>Interes bruto generado mensualmente: </strong>
              </p>
            </Col>
            <Col className="mb-2">
              <div className="border rounded shadow text-center">
                <p className="m-0">
                  $
                  {(
                    parseFloat(this.state.investAmount) *
                    parseFloat(this.props.fund.interest / 100 / 12)
                  )
                    .toFixed(2)
                    .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                </p>
              </div>
            </Col>
          </Row>
          {this.props.fund.max_invest_value !== this.state.investAmount && (
            <Row className="mt-3">
              <Col>
                <Alert className="alert-default">
                  <strong>Fondo Abierto:</strong> Tu inversión se realizara,
                  pero no comenzaras a recibir intereses hasta que se termine el
                  proceso de fondeo.
                </Alert>
              </Col>
            </Row>
          )}
          <hr className="mt-3 mb-1" />
        </Container>
      </div>
      <div className="modal-footer bg-secondary">
        <Container>
          {this.state.amountError && (
            <Row className="pb-3 pt-0">
              <Col>
                <Alert className="alert-danger">{this.state.amountError}</Alert>
              </Col>
            </Row>
          )}
          <Button
            className="text-white"
            color="danger"
            type="button"
            onClick={this.closeModal}
          >
            Cancelar
          </Button>
          <Button
            className="float-right"
            color="success"
            type="button"
            onClick={this.compleateBuy}
          >
            Confirmar
          </Button>
        </Container>
      </div>
    </>
  );

  compleateBuy = async () => {
    const resBuyFund = await buyFund(
      this.context.session.id,
      this.props.fund.id,
      this.state.investAmount
    );

    if (resBuyFund.success) {
      this.setState({
        amountError: null,
        showConfirmation: false,
        showModal: false,
        investAmount: 0,
        success: true,
      });
    } else {
      switch (resBuyFund.status) {
        case "VALUE_GREATER_THAN_MAX_FUND_AMOUNT":
          this.setState({
            amountError:
              "El máximo a invertir en este fondo es de: $" +
              this.props.fund.max_invest_value.replace(
                /\d(?=(\d{3})+\.)/g,
                "$&,"
              ),
          });
          break;
        case "VALUE_LESS_THAN_MIN_FUND_AMOUNT":
          this.setState({
            amountError:
              "El mínimo a invertir en este fondo es de: $" +
              this.props.fund.min_invest_amount.replace(
                /\d(?=(\d{3})+\.)/g,
                "$&,"
              ),
          });
          break;
        case "NOT_ENOUGH_BALANCE":
          this.setState({ amountError: "No cuentas con el saldo suficiente." });
          break;
        default:
          this.setState({
            amountError: "Ocurrió un error al procesar la inversión.",
          });
          break;
      }
    }
  };

  notEnoughBalance = () => (
    <>
      <div className="modal-header bg-default modal-default">
        <h2 className="modal-title" id="modal-title-default">
          Unirse al Fondo de inversión
        </h2>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={this.closeModal}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body bg-secondary rounded pb-0">
        <Container>
          <h3 className="text-center">
            ¡Ups! <br />
            Parece que aun no tienes saldo suficiente en tu cuenta para comenzar
            a invertir. <br />
            <br />
          </h3>
          <p className="col text-center">
            Recuerda que debes tener un monto de al menos <b>$5,000.00 MXN</b>{" "}
            dentro de tu cuenta para poder invertir en un fondo.
          </p>
        </Container>
        <div className="modal-footer bg-secondary">
          <Container className="text-center">
            <Button
              className=""
              color="primary"
              type="button"
              onClick={() => this.props.history.push("/dashboard/add-money")}
            >
              Agregar Saldo
            </Button>
          </Container>
        </div>
      </div>
    </>
  );

  render() {
    return (
      <>
        <Modal
          className="modal-dialog-centered"
          isOpen={this.state.showModal}
          toggle={() => this.setState({ showModal: !this.state.showModal })}
          onClosed={() => this.setState({ showConfirmation: false })}
        >
          {this.state.showNotEnoughBalance ? (
            this.notEnoughBalance()
          ) : (
            <React.Fragment>
              {this.state.showConfirmation
                ? this.confirmModal()
                : this.fundModal()}
            </React.Fragment>
          )}
        </Modal>

        <Modal
          className="modal-dialog-centered"
          isOpen={this.state.success}
          toggle={this.closeSuccess}
        >
          <div className="modal-header">
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={this.closeSuccess}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="py-3 text-center">
              <i className="ni ni-check-bold ni-3x text-success" />
              <h4 className="heading mt-4">¡Inversión realizada!</h4>
              <p>Se ha realizado la inversión de forma correcta.</p>
            </div>
          </div>
          <div className="modal-footer justify-content-center">
            <Button
              className="text-white"
              color="success"
              data-dismiss="modal"
              type="button"
              onClick={this.closeSuccess}
            >
              Continuar
            </Button>
          </div>
        </Modal>
      </>
    );
  }
}
JoinFundModal.contextType = SessionContext;

export default JoinFundModal;
