import { Card, CardBody, CardTitle, Col, Row } from "reactstrap";

import React from "react";

class Header extends React.Component {
  render() {
    return (
      <>
        {this.props.showStats ? (
          <div className="header bg-gradient-darker pb-8 pt-5 pt-md-7">
            <div className="mx-3">
              <div className="header-body">
                <Row>
                  <Col lg="6" xl="3" xs="6">
                    <Card className="card-stats mb-4 mb-xl-0">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle
                              tag="h5"
                              className="text-uppercase text-muted mb-0"
                            >
                              Saldo disponible
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                              $
                              {this.props.creditAvailable
                                ? this.props.creditAvailable.replace(
                                    /\d(?=(\d{3})+\.)/g,
                                    "$&,"
                                  )
                                : ""}
                            </span>
                          </div>
                          <Col className="col-auto">
                            <div className="icon icon-shape bg-gradient-green text-white rounded-circle shadow">
                              <i className="ni ni-money-coins" />
                            </div>
                          </Col>
                        </Row>
                        {/* <p className="mt-3 mb-0 text-muted text-sm">
                          <span className="text-success mr-2">
                            <i className="fa fa-arrow-up" /> 2%
                          </span>{" "}
                          <span className="text-nowrap">
                            Desde el último mes
                          </span>
                        </p> */}
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="6" xl="3" xs="6">
                    <Card className="card-stats mb-4 mb-xl-0">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle
                              tag="h5"
                              className="text-uppercase text-muted mb-0"
                            >
                              Total invertido
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                              $
                              {this.props.creditInvested
                                ? this.props.creditInvested.replace(
                                    /\d(?=(\d{3})+\.)/g,
                                    "$&,"
                                  )
                                : ""}
                            </span>
                          </div>
                          <Col className="col-auto">
                            <div className="icon icon-shape bg-gradient-orange text-white rounded-circle shadow">
                              <i className="ni ni-chart-pie-35" />
                            </div>
                          </Col>
                        </Row>
                        {/* <p className="mt-3 mb-0 text-muted text-sm">
                          <span className="text-grey mr-2">
                            <i className="fas fa-minus" /> 0%
                          </span>{" "}
                          <span className="text-nowrap">
                            Desde el último mes
                          </span>
                        </p> */}
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="6" xl="3" xs="6">
                    <Card className="card-stats mb-4 mb-xl-0">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle
                              tag="h5"
                              className="text-uppercase text-muted mb-0"
                            >
                              Saldo en fondeo
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                              $
                              {this.props.creditPendingFund
                                ? this.props.creditPendingFund.replace(
                                    /\d(?=(\d{3})+\.)/g,
                                    "$&,"
                                  )
                                : ""}
                            </span>
                          </div>
                          <Col className="col-auto">
                            <div className="icon icon-shape bg-yellow text-white rounded-circle shadow">
                              <i className="fas fa-percent" />
                            </div>
                          </Col>
                        </Row>
                        {/* <p className="mt-3 mb-0 text-muted text-sm">
                          <span className="text-success mr-2">
                            <i className="fas fa-arrow-up" /> 2%
                          </span>{" "}
                          <span className="text-nowrap">
                            Desde el último mes
                          </span>
                        </p> */}
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="6" xl="3" xs="6">
                    <Card className="card-stats mb-4 mb-xl-0">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle
                              tag="h5"
                              className="text-uppercase text-muted mb-0"
                            >
                              Valor de tu cuenta
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                              $
                              {this.props.walletValue
                                ? this.props.walletValue.replace(
                                    /\d(?=(\d{3})+\.)/g,
                                    "$&,"
                                  )
                                : ""}
                            </span>
                          </div>
                          <Col className="col-auto">
                            <div className="icon icon-shape bg-info text-white rounded-circle shadow">
                              <i className="fas fa-wallet" />
                            </div>
                          </Col>
                        </Row>
                        {/* <p className="mt-3 mb-0 text-muted text-sm">
                          <span className="text-success mr-2">
                            <i className="fas fa-arrow-up" /> 2%
                          </span>{" "}
                          <span className="text-nowrap">
                            Desde el último mes
                          </span>
                        </p> */}
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        ) : (
          <div className="header bg-gradient-darker pb-7 pt-5 pt-md-7"></div>
        )}
      </>
    );
  }
}

export default Header;
