import {
  Alert,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardText,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Modal,
  Row,
  Spinner,
} from "reactstrap";

import CurrencyInput from "react-currency-input-field";
import Header from "components/Headers/Header.js";
import React from "react";
import { SessionContext } from "contexts/SessionContext";
import { getUserBankCards } from "services/userBankCard";
import { whithdrawUserBalance } from "services/userBalance";

class RemoveMoney extends React.Component {
  state = {
    loading: true,
    errorLoading: false,
    cards: null,
    amount: "1000.00",
    notEnoughBalance: false,
    errorWithdraw: false,
    successWithdraw: false,
    errorAmount: false,
    showModal: false,
    selectedCard: null,
  };

  getBankCards = async () => {
    const resCards = await getUserBankCards(this.context.session.id);

    if (!resCards || !resCards.success) {
      this.setState({ loading: false, errorLoading: true });
    } else {
      if (
        resCards.data.length >= 1 &&
        resCards.data.filter((card) => card.status === 1).length >= 1
      ) {
        this.setState({
          cards: resCards.data.filter((card) => card.status === 1),
          loading: false,
          errorLoading: false,
          selectedCard: resCards.data.filter((card) => card.status === 1)[0].id,
        });
      } else {
        this.setState({
          loading: false,
          cards: null,
          errorLoading: false,
          showAddAccount: true,
        });
      }
    }
  };

  withdraw = async () => {
    if (this.state.amount < 10) {
      this.setState({
        errorWithdraw: false,
        notEnoughBalance: false,
        successWithdraw: false,
        errorAmount: true,
        showModal: false,
      });
    } else {
      const resWithdraw = await whithdrawUserBalance(
        this.context.session.id,
        this.state.amount,
        this.state.selectedCard
      );
      if (!resWithdraw) {
        this.setState({
          errorWithdraw: true,
          notEnoughBalance: false,
          successWithdraw: false,
          errorAmount: false,
          showModal: false,
        });
      } else if (resWithdraw.status === "NOT_ENOUGH_BALANCE") {
        this.setState({
          notEnoughBalance: true,
          errorWithdraw: false,
          successWithdraw: false,
          errorAmount: false,
          showModal: false,
        });
      } else if (resWithdraw.success) {
        this.setState({
          successWithdraw: true,
          errorWithdraw: false,
          notEnoughBalance: false,
          errorAmount: false,
          showModal: false,
        });
      } else {
        this.setState({
          errorWithdraw: true,
          notEnoughBalance: false,
          successWithdraw: false,
          errorAmount: false,
          showModal: false,
        });
      }
      this.getBankCards();
    }
  };

  async componentDidMount() {
    this.getBankCards();
  }

  render() {
    return (
      <>
        <Header />

        {/* Modal confirmation */}
        {this.state.selectedCard && (
          <Modal
            className="modal-dialog-centered"
            isOpen={this.state.showModal}
            toggle={() => this.setState({ showModal: !this.state.showModal })}
          >
            <div className="modal-header bg-default modal-default">
              <h2 className="modal-title" id="modal-title-default">
                Retirar Saldo
              </h2>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() =>
                  this.setState({ showModal: !this.state.showModal })
                }
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="pb-3 pt-0 mt-0 text-center">
                <i className="ni ni-money-coins ni-3x text-yellow" />

                <Row className="heading mt-4">
                  <Col>
                    <h4>Monto a retirar:</h4>
                  </Col>
                  <Col>
                    <div className="border rounded shadow text-center">
                      <p className="m-0">${this.state.amount}</p>
                    </div>
                  </Col>
                </Row>
                <Row className="heading mt-4">
                  <Col>
                    <h4>Cuenta bancaria:</h4>
                  </Col>
                  <Col>
                    <div className="border rounded shadow text-center">
                      <p className="m-0">
                        ****{" "}
                        {
                          this.state.cards.find(
                            (card) => card.id === this.state.selectedCard
                          ).last_digits
                        }
                      </p>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
            <div className="modal-footer">
              <Container>
                <Button
                  className="text-white"
                  color="danger"
                  type="button"
                  onClick={() =>
                    this.setState({ showModal: !this.state.showModal })
                  }
                >
                  Cancelar
                </Button>
                <Button
                  className="text-white float-right"
                  color="success"
                  data-dismiss="modal"
                  type="button"
                  onClick={this.withdraw}
                >
                  Confirmar
                </Button>
              </Container>
            </div>
          </Modal>
        )}

        {/* Page content */}
        <Container className="mt--7" fluid>
          {this.state.loading ? (
            <Row>
              <Col>
                <Card className="shadow border-0">
                  <CardHeader className="bg-transparent">
                    <Row className="align-items-center">
                      <Col md="9">
                        <h1 className="mb-0 mt-1 pb-1">-</h1>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Row className="pt-4 justify-content-center">
                      <Col className="text-center">
                        <Spinner style={{ width: "3rem", height: "3rem" }} />{" "}
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          ) : (
            <Row>
              <Col>
                <Card className="shadow border-0">
                  <CardHeader className="bg-transparent">
                    <Row className="align-items-center">
                      <Col md="9">
                        <h1 className="mb-0 mt-1 pb-1">
                          Retirar saldo de tu cuenta
                        </h1>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Row className="pb-4">
                      <Col className="text-center">
                        <CardText className="mt-0">
                          Puedes retirar tu saldo disponible en cualquier
                          momento o puedes conservarlo para invertirlo
                          nuevamente.
                        </CardText>
                      </Col>
                    </Row>

                    {this.state.cards ? (
                      <>
                        <Form role="form">
                          <Container>
                            {this.state.notEnoughBalance && (
                              <Row className="mt-0">
                                <Col>
                                  <Alert className="alert-danger">
                                    <strong>Saldo insuficiente:</strong> No
                                    cuentas con el saldo disponible suficiente
                                    para realizar ese retiro.
                                  </Alert>
                                </Col>
                              </Row>
                            )}
                            {this.state.successWithdraw && (
                              <Row className="mt-0">
                                <Col>
                                  <Alert className="alert-success">
                                    <strong>Retiro exitoso:</strong> El retiro
                                    de saldo fue realizado con éxito.
                                  </Alert>
                                </Col>
                              </Row>
                            )}
                            {this.state.errorAmount && (
                              <Row className="mt-0">
                                <Col>
                                  <Alert className="alert-danger">
                                    <strong>Error:</strong> El monto minimo de
                                    retiro es de $10.00 MXN.
                                  </Alert>
                                </Col>
                              </Row>
                            )}
                            {this.state.errorWithdraw && (
                              <Row className="mt-0">
                                <Col>
                                  <Alert className="alert-danger">
                                    <strong>Error:</strong> Hubo un error al
                                    intentar realizar tu retiro.
                                  </Alert>
                                </Col>
                              </Row>
                            )}
                            <Row className="justify-content-center">
                              <Col md="6">
                                <FormGroup className="mb-1">
                                  <label
                                    className="form-control-label text-muted"
                                    htmlFor="invest-amount"
                                  >
                                    Monto a retirar
                                  </label>
                                  <InputGroup className="input-group">
                                    <InputGroupAddon addonType="prepend">
                                      <InputGroupText>
                                        <i className="fa fa-dollar-sign" />
                                      </InputGroupText>
                                    </InputGroupAddon>
                                    <CurrencyInput
                                      onFocus={this.handleFocus}
                                      className="form-control"
                                      placeholder={1000}
                                      defaultValue={1000}
                                      allowDecimals={true}
                                      allowNegativeValue={false}
                                      onValueChange={(value) => {
                                        this.setState({
                                          amount: value,
                                        });
                                      }}
                                    />
                                  </InputGroup>
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row className="pt-2 justify-content-center">
                              <Col md="6">
                                <FormGroup>
                                  <label
                                    className="form-control-label text-muted"
                                    htmlFor="exampleFormControlSelect1"
                                  >
                                    Cuenta Bancaria
                                  </label>
                                  <InputGroup className="input-group">
                                    <InputGroupAddon addonType="prepend">
                                      <InputGroupText>
                                        <i className="fa fa-credit-card" />
                                      </InputGroupText>
                                    </InputGroupAddon>
                                    <Input
                                      className="form-control"
                                      id="exampleFormControlSelect1"
                                      type="select"
                                      defaultValue={this.state.cards[0].id}
                                      onChange={(e) =>
                                        this.setState({
                                          selectedCard: e.target.value,
                                        })
                                      }
                                    >
                                      {this.state.cards.map((card) => (
                                        <option key={card.id} value={card.id}>
                                          **** {card.last_digits}
                                        </option>
                                      ))}
                                    </Input>
                                  </InputGroup>
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row className="pt-2 justify-content-center">
                              <Col className="text-center">
                                <Button
                                  className="btn-icon btn-3"
                                  color="primary"
                                  type="button"
                                  disabled={this.state.amount === undefined}
                                  onClick={() =>
                                    this.setState({ showModal: true })
                                  }
                                >
                                  Retirar
                                </Button>
                              </Col>
                            </Row>
                          </Container>
                        </Form>
                        <Row className="pt-5">
                          <Col className="text-center">
                            <small>
                              Una vez realizado el retiro, lo veras reflejado
                              entre 1 y 2 días en la cuenta bancaria
                              seleccionada.
                            </small>
                          </Col>
                        </Row>
                      </>
                    ) : (
                      <>
                        <Row className="pb-4">
                          <Col className="text-center">
                            <CardText className="mt-0 lead">
                              Es necesario tener al menos una cuenta bancaria
                              dada de alta para poder realizar retiros.
                            </CardText>
                          </Col>
                        </Row>
                        <Row className="pt-2 justify-content-center">
                          <Col className="text-center">
                            <Button
                              className="btn-icon btn-3"
                              color="primary"
                              type="button"
                              onClick={() =>
                                this.props.history.push(
                                  "/dashboard/bank-accounts"
                                )
                              }
                            >
                              Agregar
                            </Button>
                          </Col>
                        </Row>
                      </>
                    )}

                    {/* <AddBankAccountForm /> */}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </>
    );
  }
}
RemoveMoney.contextType = SessionContext;

export default RemoveMoney;
