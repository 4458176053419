import { Alert, Card, CardBody, CardFooter, Col, Row } from "reactstrap";

import React from "react";
import { SessionContext } from "contexts/SessionContext";
import { sendValidateEmail } from "services/auth";

class VerifyAccount extends React.Component {
  state = {};
  componentDidMount() {
    // Session Validation
    this.context.checkSession();
    if (!this.context.session.id) {
      this.props.history.push("/auth/login");
    } else if (this.context.session.status === 3) {
      this.props.history.push("/auth/complete-register");
    }
  }

  resendEmailAction = async () => {
    if (!this.state.resendEmail) {
      await sendValidateEmail(this.context.session.id);
      this.setState({ resendEmail: true });
    }
  };

  render() {
    return (
      <>
        <Col lg="5" md="7">
          <Card className="bg-secondary shadow border-0">
            <CardBody className="px-lg-5 py-lg-5 text-center">
              <div className="text-center text-muted mb-5">
                <h1>Verificación de correo</h1>
              </div>
              <p>
                Consulte la bandeja de entrada de su correo electrónico para ver
                el enlace de activación.
                <br />·<br />
                Si no encuentra el correo electrónico, revise también su carpeta
                de correo no deseado/spam.
              </p>
              <div>
                {this.state.resendEmail && (
                  <Alert color="success" className="mt-4 mb--4">
                    <span className="alert-inner--icon">
                      <i className="ni ni-email-83 m-2" />
                    </span>{" "}
                    Correo enviado nuevamente.
                  </Alert>
                )}
              </div>
            </CardBody>
            <CardFooter>
              <Row>
                <Col className="text-left">
                  <small>¿No recibiste el correo?</small>
                  <br />
                  <button
                    className="m-0 p-0 btn btn-link text-primary"
                    onClick={this.resendEmailAction}
                  >
                    <small>Enviar nuevo correo electrónico de activación</small>
                  </button>
                </Col>
              </Row>
            </CardFooter>
          </Card>
        </Col>
      </>
    );
  }
}
VerifyAccount.contextType = SessionContext;

export default VerifyAccount;
