import { Button, Card, CardBody, Form, Col, Row, Spinner } from "reactstrap";
import React from "react";
import { SessionContext, setSessionCookie } from "contexts/SessionContext";
import { updateUser } from "services/user";
import { requestUserApproval } from "services/auth";

class AcceptContract extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      acceptCheckbox: false,
      loading: false,
    };
  }
  componentDidMount() {
    // Session Validation
    this.context.checkSession();
    console.log(this.context);
    if (this.context.session.status !== 5) {
      this.props.history.push("/auth/login");
    }
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    this.setState({ loading: true });
    const updateUserResponse = await updateUser({
      id: this.context.session.id,
      status: 6,
    });
    if (updateUserResponse.user) {
      const requestUserApprovalResponse = await requestUserApproval(
        this.context.session.id
      );
      if (requestUserApprovalResponse.success) {
        await setSessionCookie(updateUserResponse.user);
        this.context.checkSession();
        this.props.history.push("/dashboard");
      }
    }
  };

  render() {
    return (
      <Col lg="12" md="12">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-muted mb-4">
              <h1>Información personal</h1>
            </div>
            <div className="mb-4">
              <p>
                ¡Bienvenido a Inversa! Estás a un paso de poder hacer crecer tu
                dinero de una forma fácil y segura.
                <br />
                Ya solo falta completar tu información personal.
              </p>
            </div>
            <hr />
            <div>
              <h2 className="mb-4 text-center">Aceptacion de Contrato.</h2>
              <Form role="form" onSubmit={this.handleSubmit}>
                <Row>
                  <Col
                    lg="12"
                    sm="12"
                    className="text-dark strong rounded p-3"
                    style={{
                      height: "500px",
                      overflowY: "scroll",
                      backgroundColor: "rgb(233 233 233)",
                    }}
                  >
                    <div>
                      <p className="text-justify">
                        CONTRATO DE PR&Eacute;STAMO MERCANTIL QUE CELEBRAN
                        INVERSA FINANCIERA DIGITAL, SA DE CV, EN LO SUCESIVO
                        &ldquo;INVERSA&rdquo;, CON DOMICILIO EN AV. SONTERRA
                        #3016, ALMENDROS INT #46, COL. SONTERRA,
                        QUER&Eacute;TARO, C.P. 76235, QUER&Eacute;TARO, CON
                        P&Aacute;GINA DE INTERNET DENOMINADA
                        https://investor.inversadigital.mx Y EL INVERSIONISTA
                        (TAL COMO SE DEFINE M&Aacute;S ADELANTE).
                      </p>

                      <p></p>

                      <p className="mt-5">DEFINICIONES</p>
                      <p>
                        &Uacute;nico. Para los efectos del presente contrato, se
                        entender&aacute; por:
                      </p>
                      <ol>
                        <li className="ml-3">
                          <p>
                            Inversionista: {this.context.session.name}{" "}
                            {this.context.session.last_name}{" "}
                            {this.context.session.second_last_name}
                          </p>
                        </li>

                        <li className="ml-3">
                          <p>
                            Domicilio Inversionista: Domicilio el ubicado en{" "}
                            {this.context.session.street}{" "}
                            {this.context.session.ext_number}{" "}
                            {this.context.session.suburb}{" "}
                            {this.context.session.int_number}{" "}
                            {this.context.session.city}{" "}
                            {this.context.session.postal_code},{" "}
                            {this.context.session.state}.
                          </p>
                        </li>

                        <li className="ml-3">
                          <p>
                            Plataforma INVERSA. El programa de c&oacute;mputo
                            consultable a trav&eacute;s de la direcci&oacute;n
                            de internet denominada
                            https://investor.inversadigital.mx;
                          </p>
                        </li>

                        <li className="ml-3">
                          <p>
                            Orden de Inversi&oacute;n. La solicitud formulada
                            por el Inversionista a trav&eacute;s de la
                            Plataforma INVERSA para dar en pr&eacute;stamo
                            mercantil una cantidad cierta y en dinero.
                          </p>
                        </li>

                        <li className="ml-3">
                          <p>
                            Cuenta de Inversi&oacute;n: La cuenta dada de alta
                            por el Inversionista a trav&eacute;s de la
                            Plataforma INVERSA la cual est&aacute;
                            expl&iacute;citamente aperturada a nombre del
                            inversionista.
                          </p>
                        </li>

                        <li className="ml-3">
                          <p>
                            Monto Invertido: La cantidad que se especifique en
                            cada Orden de Inversi&oacute;n que el Inversionista
                            se suscriba a trav&eacute;s de la Plataforma
                            INVERSA.
                          </p>
                        </li>

                        <li className="ml-3">
                          <p>
                            Plazo: El n&uacute;mero de meses contados a partir
                            de la fecha de inicio del fondo de inversi&oacute;n
                            suscrito por medio de la Plataforma INVERSA.
                          </p>
                        </li>

                        <li className="ml-3">
                          <p>
                            Inter&eacute;s Ordinario: la cantidad que resulte de
                            multiplicar el Monto Invertido por el factor de
                            porcentaje mensual del fondo suscrito a
                            trav&eacute;s de la Plataforma INVERSA menos
                            impuestos y comisiones.
                          </p>
                        </li>

                        <li className="ml-3">
                          <p>
                            Beneficiario:{" "}
                            {this.context.session.beneficiary_name}
                          </p>
                        </li>

                        <li className="ml-3">
                          <p>
                            Correo electr&oacute;nico beneficiario:{" "}
                            {this.context.session.beneficiary_email}
                          </p>
                        </li>

                        <li className="ml-3">
                          <p>
                            Origen de los dep&oacute;sitos para INVERSA:{" "}
                            {this.context.session.resources_source.replace(
                              ",",
                              ", "
                            )}
                          </p>
                        </li>

                        <p></p>
                      </ol>

                      <p className="mt-5">DECLARACIONES</p>

                      <p> Declara el Inversionista:</p>
                      <ol>
                        <li>
                          <p>
                            En caso de ser persona f&iacute;sica. Que es una
                            persona f&iacute;sica mayor de edad y cuenta con la
                            capacidad necesaria para obligarse en sus
                            t&eacute;rminos de acuerdo con las leyes de la
                            Rep&uacute;blica Mexicana;
                          </p>
                        </li>

                        <li>
                          <p>
                            En caso de ser persona moral. Que es una Sociedad,
                            constituida de conformidad con las leyes vigentes en
                            los Estados Unidos Mexicanos, inscrita en el
                            Registro P&uacute;blico de la Propiedad y del
                            Comercio del Distrito Federal y su objeto social le
                            permite la celebraci&oacute;n del presente contrato;
                          </p>
                        </li>

                        <li>
                          <p>
                            Que para efectos del presente contrato se&ntilde;ala
                            como domicilio el ubicado en el apartado de
                            definiciones del presente contrato bajo el rubro
                            Domicilio Inversionista;
                          </p>
                        </li>

                        <li>
                          <p>
                            En caso de ser extranjero: Que cuenta con pasaporte
                            y documento oficial expedido por el Instituto
                            Nacional de Migraci&oacute;n o forma migratoria,
                            contar con documento comprobatorio de su domicilio
                            en su pa&iacute;s de origen, as&iacute; como su
                            inscripci&oacute;n ante el Registro Federal de
                            Contribuyentes y tener un comprobante de domicilio
                            fiscal en M&eacute;xico, adem&aacute;s de mantener
                            en total orden todas y cada una de las obligaciones
                            que disponga el marco jur&iacute;dico mexicano para
                            acreditar la legal estancia en el pa&iacute;s.
                          </p>
                        </li>

                        <li>
                          <p>
                            Que conforme a su objeto social le est&aacute;
                            permitido celebrar el presente contrato y obligarse
                            en los t&eacute;rminos del mismo;
                          </p>
                        </li>

                        <li>
                          <p>
                            Que su representante cuenta con las facultades
                            necesarias para obligarlo en los t&eacute;rminos del
                            presente contrato, mismas que no le han sido
                            revocadas, modificadas o limitadas en forma alguna.
                          </p>
                        </li>
                      </ol>
                      <p className="mt-3">
                        {" "}
                        Declara INVERSA, a trav&eacute;s de su representante:
                      </p>
                      <ol>
                        <li>
                          <p>
                            Que es una Sociedad An&oacute;nima de Capital
                            Variable, constituida de conformidad con las leyes
                            vigentes en los Estados Unidos Mexicanos, inscrita
                            en el Registro P&uacute;blico de la Propiedad y del
                            Comercio del Estado de Queretaro;
                          </p>
                        </li>

                        <li>
                          <p>
                            Que conforme a su objeto social le est&aacute;
                            permitido celebrar el presente contrato y obligarse
                            en los t&eacute;rminos del mismo;
                          </p>
                        </li>

                        <li>
                          <p>
                            Que su representante cuenta con las facultades
                            necesarias para obligarla en los t&eacute;rminos del
                            presente contrato, mismas que no le han sido
                            revocadas, modificadas o limitadas en forma alguna.
                          </p>
                        </li>
                      </ol>
                      <p className="mt-3"> Declaran las partes:</p>
                      <ol>
                        <li>
                          <p>
                            Que en el presente contrato no existe error, dolo,
                            mala fe o cualquier otro vicio de la voluntad,
                            as&iacute; como no existe lesi&oacute;n que pudiese
                            afectar la validez del presente contrato;
                          </p>
                        </li>

                        <li>
                          <p>
                            Que se reconocen la personalidad con la que
                            comparecen a la celebraci&oacute;n de este contrato
                            y expresamente convienen en someterse a las
                            obligaciones contenidas en las siguientes:
                          </p>
                        </li>
                      </ol>
                      <p></p>

                      <p className="mt-3">C L &Aacute; U S U L A S</p>
                      <ol>
                        <li>
                          <p>
                            Entrega y disposici&oacute;n. El Inversionista se
                            obliga a entregar a INVERSA el Monto Invertido, a
                            trav&eacute;s de transferencia electr&oacute;nica
                            (SPEI) o pago de servicio referenciado (CIE), en la
                            cuenta que INVERSA le indique al Inversionista de
                            tiempo en tiempo.
                          </p>
                        </li>

                        <li>
                          <p>
                            Inter&eacute;s Ordinario. Ambas partes acuerdan que
                            INVERSA estar&aacute; obligado a reflejar al
                            Inversionista el Inter&eacute;s Ordinario (menos
                            impuestos) de forma mensual a trav&eacute;s de la
                            Plataforma INVERSA, a partir de la fecha de inicio
                            de el fondo de inversi&oacute;n al que se encuentra
                            suscrito el Inversionista.
                          </p>
                        </li>

                        <li>
                          <p>
                            T&eacute;rmino. Las partes acuerdan que las
                            obligaciones establecidas en el presente contrato se
                            sujetan al Plazo se&ntilde;alado en el fondo de
                            inversi&oacute;n al que se encuentra suscrito el
                            Inversionista. Las partes acuerdan que el Plazo se
                            renovar&aacute; autom&aacute;ticamente por un
                            periodo de tiempo igual en caso de que el
                            Inversionista no solicite la devoluci&oacute;n del
                            Monto Invertido dentro de los 5 d&iacute;as
                            h&aacute;biles anteriores y posteriores a la fecha
                            en que expire el Plazo.
                          </p>
                        </li>

                        <li>
                          <p>
                            Terminaci&oacute;n Anticipada. Ambas partes acuerdan
                            que INVERSA podr&aacute; dar por terminado
                            anticipadamente el presente contrato, sin necesidad
                            de declaraci&oacute;n judicial o administrativa para
                            tal efecto, mediante i) un aviso enviado con 3
                            d&iacute;as h&aacute;biles de anticipaci&oacute;n a
                            la fecha en que opere la terminaci&oacute;n
                            anticipada y; ii) la devoluci&oacute;n anticipada
                            del Monto Invertido y el monto de Inter&eacute;s
                            Ordinario que se hubiese generado hasta la fecha
                            efectiva de devoluci&oacute;n del Monto Invertido.
                          </p>
                        </li>

                        <li>
                          <p>
                            Cesi&oacute;n. Es voluntad de las partes pactar que
                            el Inversionista no podr&aacute; ceder los derechos
                            subjetivos derivados de este contrato sin el
                            consentimiento de INVERSA. . De igual manera las
                            partes acuerdan que INVERSA podr&aacute; hacer
                            cesi&oacute;n de los derechos y deberes
                            jur&iacute;dicos derivados del presente contrato
                            siempre y cuando cuente con el consentimiento previo
                            y por escrito del Inversionista.
                          </p>
                        </li>

                        <li>
                          <p>
                            Impuestos, Derechos y Contribuciones. Todos los
                            impuestos, derechos y dem&aacute;s gastos, que se
                            originen en relaci&oacute;n con la
                            celebraci&oacute;n del presente contrato
                            ser&aacute;n realizados por cada una de las partes
                            seg&uacute;n les corresponda de conformidad con las
                            disposiciones legales aplicables. Sin ser
                            &oacute;bice de lo anterior, en caso de que el
                            Inversionista sea una persona f&iacute;sica INVERSA,
                            quedar&aacute; obligado a realizar la
                            retenci&oacute;n entero y pago los pagos por
                            concepto de retenciones por intereses a las
                            autoridades correspondientes y a emitir el
                            comprobante que corresponda, en t&eacute;rminos de
                            las disposiciones legales aplicables.
                          </p>
                        </li>

                        <li>
                          <p>
                            Ambas partes acuerdan que una vez que INVERSA haya
                            devuelto la totalidad del dinero dado en
                            pr&eacute;stamo m&aacute;s los intereses
                            correspondientes, el Inversionista, se obliga a
                            extender el recibo correspondiente.
                          </p>
                        </li>

                        <li>
                          <p>
                            Ley Antilavado. El Inversionista se obliga a
                            entregar, en el momento en el que le sea solicitada,
                            toda la informaci&oacute;n de identificaci&oacute;n
                            que le sea requerida para su correcta
                            identificaci&oacute;n en t&eacute;rminos de la Ley
                            Federal para la Prevenci&oacute;n e
                            Identificaci&oacute;n de Operaciones con Recursos de
                            Procedencia Il&iacute;cita su reglamento y
                            disposiciones de car&aacute;cter general aplicables.
                          </p>
                        </li>

                        <li>
                          <p>
                            T&iacute;tulos de las cl&aacute;usulas. Las partes
                            acuerdan que los t&iacute;tulos de las
                            cl&aacute;usulas son exclusivamente para facilitar
                            la lectura del mismo, por lo que para interpretar la
                            voluntad de las partes se deber&aacute; de estar a
                            los contenidos de cada cl&aacute;usula, as&iacute;
                            como para su interpretaci&oacute;n se estar&aacute;
                            a la redacci&oacute;n entera del clausulado del
                            presente convenio, mismo que deber&aacute;
                            interpretarse en su conjunto.
                          </p>
                        </li>

                        <li>
                          <p>
                            Acuerdo final. Es voluntad de las partes pactar que
                            todo lo contenido en el presente contrato constituye
                            el convenio final celebrado entre ellas con
                            relaci&oacute;n al Objeto del presente contrato, en
                            consecuencia, no tendr&aacute; valor jur&iacute;dico
                            vinculante entre las partes cualquier promesa,
                            pacto, negociaci&oacute;n o acuerdo realizado con
                            anterioridad a la fecha del presente convenio que se
                            oponga al contenido de las cl&aacute;usulas
                            aqu&iacute; establecidas.
                          </p>
                        </li>

                        <li>
                          <p>
                            Nulidad de las cl&aacute;usulas. En caso de que
                            cualquier cl&aacute;usula sea declarada nula por la
                            autoridad judicial competente, las partes acuerdan
                            que dicha cl&aacute;usula nula se separar&aacute;
                            del contenido del presente convenio. El presente
                            convenio su vigencia con aquellas cl&aacute;usulas
                            que no hayan sido declaradas nulas, en lo que
                            resulte aplicable.
                          </p>
                        </li>

                        <li>
                          <p>
                            Es voluntad de las partes pactar que cualquier
                            notificaci&oacute;n o comunicaci&oacute;n que exista
                            entre las partes derivada del presente convenio,
                            deber&aacute; constar por escrito debidamente
                            firmadas aut&oacute;grafa o electr&oacute;nicamente
                            seg&uacute;n sea el caso, pudiendo ser por medios
                            impresos enviados a sus domicilios establecidos en
                            las declaraciones del presente convenio con acuse de
                            recibo, o por medios electr&oacute;nicos mediante
                            correo electr&oacute;nico enviado a sus direcciones
                            de correo electr&oacute;nico establecidos en las
                            declaraciones del presente convenio.
                          </p>
                        </li>

                        <p className="text-justify">
                          Para el caso en que los escritos sean
                          electr&oacute;nicos, se entender&aacute; que los
                          mismos se encuentran contenidos en mensajes de datos y
                          se encuentran firmados electr&oacute;nicamente con
                          firma electr&oacute;nica simple, la cual consiste en
                          la identificaci&oacute;n de las partes a trav&eacute;s
                          del uso de i) sus direcciones de correo
                          electr&oacute;nico declaradas en el presente convenio,
                          por lo que por virtud de este acto las partes declaran
                          que es su estricta responsabilidad el uso y acceso a
                          sus correos electr&oacute;nicos o; ii) su cuenta de
                          usuario en la plataforma identificada con el nombre de
                          dominio https:/investor.inversadigital.mx. Cuando una
                          de las partes env&iacute;e a la otra un mensaje de
                          datos desde la direcci&oacute;n de correo
                          electr&oacute;nico que han manifestado como oficial en
                          las declaraciones del presente convenio o desde su
                          cuenta de usuario en la Plataforma identificada con el
                          nombre de dominio https:/investor.inversadigital.mx,
                          se entender&aacute; que el mensaje de datos
                          est&aacute; firmado con firma electr&oacute;nica para
                          todos los efectos legales a que haya lugar,
                          cre&aacute;ndose la presunci&oacute;n Iuris Tantum de
                          que qui&eacute;n ha enviado el mensaje de datos es el
                          representante legal de la sociedad de que se trate,
                          con poder bastante y suficiente para obligarla.
                        </p>

                        <p className="text-justify">
                          Las partes podr&aacute;n enviar, la
                          notificaci&oacute;n v&iacute;a correo
                          electr&oacute;nico, a que se hace referencia en la
                          presente cl&aacute;usula, con copia al correo
                          electr&oacute;nicos que elijan (la cuenta de correo
                          electr&oacute;nico auxiliar), para el efecto de
                          acreditar que dicho mensaje de datos fue recibido por
                          los destinatarios respectivos. Es voluntad de las
                          partes pactar que si el mensaje de datos es recibido
                          en la cuenta de correo electr&oacute;nico auxiliar se
                          tendr&aacute; la presunci&oacute;n Iuris Tantum de que
                          dicho mensaje de datos fue recibido en la cuenta de
                          correo electr&oacute;nico de la otra parte, para los
                          efectos legales a que haya lugar. As&iacute; mismo las
                          partes podr&aacute;n enviar el mensaje de datos que
                          contenga la notificaci&oacute;n o comunicaci&oacute;n
                          en t&eacute;rminos de la presente cl&aacute;usula, con
                          acuse de recibo, si es que el aplicativo que utilizan
                          para enviar correos electr&oacute;nicos lo permite.
                          Para el caso de mensajes de datos enviados a
                          trav&eacute;s de la cuenta de usuario en la Plataforma
                          identificada con el nombre de dominio
                          https:/investor.inversadigital.mx se presumir&aacute;
                          que dicha notificaci&oacute;n ha sido recibida por
                          cualquiera de las partes, salvo prueba en contrario.
                        </p>

                        <li>
                          <p>
                            Toda Orden de Inversi&oacute;n que suscriba el
                            Inversionista a trav&eacute;s de la Plataforma
                            INVERSA, ser&aacute; considerada como un Anexo al
                            presente contrato y formar&aacute; parte integrante
                            del mismo para todos efectos legales a que haya
                            lugar.
                          </p>
                        </li>

                        <li>
                          <p>
                            Jurisdicci&oacute;n y Competencia. Las partes
                            acuerdan que para la interpretaci&oacute;n y
                            cumplimiento del presente contrato ser&aacute;n
                            aplicables las Leyes vigentes en la Ciudad de
                            M&eacute;xico y competentes los Tribunales de la
                            misma entidad federativa, renunciando a cualquier
                            fuero o competencia que pudiera corresponderles en
                            el futuro por raz&oacute;n de domicilio o cualquier
                            otra circunstancia.
                          </p>
                        </li>

                        <li>
                          <p>
                            Beneficiario: En caso de cualquier eventualidad, por
                            la que el titular del presente contrato, se
                            encuentre imposibilitado para ejercer sus derechos y
                            obligaciones, la persona designada como beneficiario
                            quedar&aacute; facultada para ejercer los derechos y
                            obligaciones mencionados en el presente contrato.
                          </p>
                        </li>
                      </ol>
                      <p></p>
                    </div>
                  </Col>
                </Row>

                <Row className="mt-5 text-center">
                  <Col className="text-center">
                    <div className="custom-control custom-checkbox mb-3">
                      <input
                        className="custom-control-input"
                        id="acceptCheckbox"
                        type="checkbox"
                        onChange={() =>
                          this.setState({
                            acceptCheckbox: !this.state.acceptCheckbox,
                          })
                        }
                        checked={this.state.acceptCheckbox}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="acceptCheckbox"
                      >
                        Acepto y estoy de acuerdo con el Contrato
                      </label>
                    </div>
                    {this.state.loading ? (
                      <Spinner color="dark" />
                    ) : (
                      <Button
                        color="primary"
                        type="submit"
                        disabled={!this.state.acceptCheckbox}
                      >
                        Continuar
                      </Button>
                    )}
                  </Col>
                </Row>
                <p className="text-center mt-3 mb--2">
                  Valida que tus datos estén correctos antes de continuar.
                </p>
              </Form>
            </div>
          </CardBody>
        </Card>
      </Col>
    );
  }
}
AcceptContract.contextType = SessionContext;
export default AcceptContract;
