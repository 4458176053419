import {
  Button,
  Col,
  Container,
  Modal,
  Row,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Form,
  FormText,
  Alert,
  FormGroup,
} from "reactstrap";

import React from "react";
import { SessionContext } from "contexts/SessionContext";
import CurrencyInput from "react-currency-input-field";

import moment from "moment";
import { requestCloseFund, requestWithdrawFund } from "services/fund";

class DetailsUserFund extends React.Component {
  state = {
    showModal: false,
    showSuccessModal: false,
    showConfirmationModal: false,
    showSuccessWithdrawModal: false,
    withdrawAmount: "10",
    amountError: null,
    amountDetail: null,
  };

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      this.setState({ showModal: this.props.showModal });
    }
  }

  showConfirmation = () => {
    this.setState({
      showModal: false,
      showSuccessModal: false,
      showConfirmationModal: true,
    });
    this.props.closeModal();
  };

  closeModal = () => {
    this.setState({
      showModal: false,
      showSuccessModal: false,
      showConfirmationModal: false,
    });
    this.props.closeModal();
  };

  sendCloseFund = async () => {
    const response = await requestCloseFund(
      this.context.session.id,
      this.props.fund.fund_id
    );
    if (response.success || response.already_closed) {
      this.setState({
        showModal: false,
        showSuccessModal: true,
        showConfirmationModal: false,
      });
      this.props.closeModal();
    }
  };

  switchStatus(fund) {
    switch (fund.status) {
      case 1:
        return "En fondeo";
      case 3:
        if (fund.close_request) {
          return "Proceso de Cierre";
        } else {
          return "Activa";
        }
      default:
        return "Completada";
    }
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    let withdrawAmount = this.state.withdrawAmount;
    if (!this.state.amountDetail) {
      if (withdrawAmount === "undefined" || !withdrawAmount)
        withdrawAmount = "10";

      const resWithdraw = await requestWithdrawFund(
        this.context.session.id,
        this.props.fund.fund_id,
        withdrawAmount
      );

      if (!resWithdraw) {
        this.setState({ loading: false, errorLoading: true });
      } else {
        switch (resWithdraw.status) {
          case "SUCCESS":
            this.closeModal();
            this.setState({
              amountError: null,
              showSuccessWithdrawModal: true,
              withdrawAmount,
            });
            break;
          case "FUND_ALREADY_STARTED":
            this.setState({
              amountError:
                "Parece qeu ya se ha iniciado el fondo y no puedes retirar parte de tu inversion.",
            });
            break;
          case "VALUE_GREATER_THAN_MAX_FUND_AMOUNT":
            this.setState({
              amountError:
                "El monto máximo a retirar es de: $" +
                this.props.fund.invest_amount.replace(
                  /\d(?=(\d{3})+\.)/g,
                  "$&,"
                ),
            });
            break;
          default:
            this.setState({
              amountError: "Ocurrió un error al procesar la transacción.",
            });
            break;
        }
      }
    }
  };

  render() {
    return (
      <>
        <Modal
          className="modal-dialog-centered"
          isOpen={this.state.showModal}
          toggle={this.closeModal}
        >
          <div className="modal-header bg-default modal-default">
            <h2 className="modal-title" id="modal-title-default">
              Detalle Fondo de inversión
            </h2>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={this.closeModal}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          {this.props.fund && (
            <div className="modal-body bg-secondary rounded pb-0">
              <Container className="pb-3">
                <Row>
                  <Col className="my-auto" lg="7">
                    <p>
                      <strong>Plazo:</strong>
                    </p>
                  </Col>
                  <Col className="mb-2">
                    <div className="border rounded shadow text-center">
                      <p className="m-0">
                        {this.props.fund.period
                          ? parseInt(this.props.fund.period) + " Meses"
                          : "--"}
                      </p>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col className="my-auto" lg="7">
                    <p>
                      <strong>Interes Anual:</strong>
                    </p>
                  </Col>
                  <Col className="mb-2">
                    <div className="border rounded shadow text-center">
                      <p className="m-0">{this.props.fund.interest}%</p>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col className="my-auto" lg="7">
                    <p>
                      <strong>Total invertido:</strong>
                    </p>
                  </Col>
                  <Col className="mb-2">
                    <div className="border rounded shadow text-center">
                      <p className="m-0">
                        $
                        {this.props.fund.invest_amount.replace(
                          /\d(?=(\d{3})+\.)/g,
                          "$&,"
                        )}
                      </p>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col className="my-auto" lg="7">
                    <p>
                      <strong>Estatus:</strong>
                    </p>
                  </Col>
                  <Col className="mb-2">
                    <div className="border rounded shadow text-center">
                      <p className="m-0">
                        {this.switchStatus(this.props.fund)}
                      </p>
                    </div>
                  </Col>
                </Row>
                {this.props.fund.start_date ? (
                  <>
                    <Row>
                      <Col className="my-auto" lg="7">
                        <p>
                          <strong>Fecha de inicio:</strong>
                        </p>
                      </Col>
                      <Col className="mb-2">
                        <div className="border rounded shadow text-center">
                          <p className="m-0">
                            {moment(this.props.fund.start_date).format(
                              "DD-MM-YYYY"
                            )}
                          </p>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="my-auto" lg="7">
                        <p>
                          <strong>Fecha de terminación:</strong>
                        </p>
                      </Col>
                      <Col className="mb-2">
                        <div className="border rounded shadow text-center">
                          <p className="m-0">
                            {this.props.fund.end_date
                              ? moment(this.props.fund.end_date).format(
                                  "DD-MM-YYYY"
                                )
                              : "--"}
                          </p>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="my-auto" lg="7">
                        <p>
                          <strong>Fecha recepción de intereses:</strong>
                        </p>
                      </Col>
                      <Col className="mb-2">
                        <div className="border rounded shadow text-center">
                          <p className="m-0">
                            {this.props.fund.interests_day
                              ? "Los dias " + this.props.fund.interests_day
                              : "--"}
                          </p>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="my-auto" lg="7">
                        <p>
                          <strong>Intereses Generados:</strong>
                        </p>
                      </Col>
                      <Col className="mb-2">
                        <div className="border rounded shadow text-center">
                          <p className="m-0">
                            $
                            {this.props.fund.interests_amount
                              ? this.props.fund.interests_amount.replace(
                                  /\d(?=(\d{3})+\.)/g,
                                  "$&,"
                                )
                              : "0.00"}
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </>
                ) : (
                  <></>
                )}
                {this.props.fund.modality === 2 &&
                  this.props.fund.status === 3 && (
                    <>
                      <hr className="mt-3" />
                      <Row className="pt-0">
                        <Col className="pb-2 my-auto text-center">
                          <Button
                            color={
                              this.props.fund.can_be_closed ? "danger" : "light"
                            }
                            data-dismiss="modal"
                            type="button"
                            disabled={!this.props.fund.can_be_closed}
                            onClick={this.showConfirmation}
                          >
                            Cerrar Fondo
                          </Button>
                        </Col>
                      </Row>
                      <Row className="mt-3">
                        <Container>
                          <footer className="blockquote-footer">
                            <small>
                              Los fondos Dinámicos pueden ser cerrados después
                              de 15 días de haberse iniciado.
                            </small>
                          </footer>
                          <footer className="blockquote-footer">
                            <small>
                              Al cerrar un fondo Dinámico, se te regresa tu
                              monto de inversión y los rendimientos generados
                              hasta esa fecha. Una vez cerrado el fondo, tu
                              saldo se vera reflejado dentro de 48 a 96 horas.
                            </small>
                          </footer>
                        </Container>
                      </Row>
                    </>
                  )}

                {this.props.fund.status === 1 && (
                  <>
                    <Row className="mt-3">
                      <Col>
                        <Alert className="alert-default">
                          <strong>Retiro de Inversion:</strong> Si lo deseas,
                          puedes retirar toda o una parte de tu inversion en el
                          fondo antes de que este comience.
                        </Alert>
                      </Col>
                    </Row>
                    <Row className="mt-3 pb-3 pt-2">
                      <Col md="6" sm="12" className="pb-2 mt--4">
                        <Form role="form">
                          <FormGroup className="mb-1">
                            <label
                              className="form-control-label text-muted"
                              htmlFor="invest-amount"
                            >
                              Monto a retirar
                            </label>
                            <InputGroup className="input-group-alternative">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="fa fa-dollar-sign" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <CurrencyInput
                                onFocus={this.handleFocus}
                                className="form-control"
                                placeholder={10}
                                defaultValue={10}
                                allowDecimals={false}
                                allowNegativeValue={false}
                                onValueChange={(value) => {
                                  console.log(value);

                                  if (value % 10 === 0) {
                                    this.setState({
                                      amountDetail: "",
                                      withdrawAmount: value,
                                    });
                                  } else {
                                    this.setState({
                                      amountDetail:
                                        "Solo se aceptan cantidades con múltiplos de 10",
                                      withdrawAmount: value,
                                    });
                                  }
                                }}
                              />
                            </InputGroup>
                          </FormGroup>
                        </Form>
                      </Col>
                      <Col md="6" sm="12" className="pb-2 my-auto">
                        <Button
                          className="float-right"
                          color="danger"
                          data-dismiss="modal"
                          type="button"
                          onClick={this.handleSubmit}
                        >
                          Retirar
                        </Button>
                      </Col>
                      <Col sm="12">
                        {this.state.amountDetail && (
                          <FormText color="danger">
                            {this.state.amountDetail}
                          </FormText>
                        )}
                      </Col>
                    </Row>
                    {this.state.amountError && (
                      <Row className="pb-3 pt--2">
                        <Col>
                          <Alert className="alert-danger">
                            {this.state.amountError}
                          </Alert>
                        </Col>
                      </Row>
                    )}
                  </>
                )}
              </Container>
            </div>
          )}
        </Modal>

        <Modal
          className="modal-dialog-centered"
          isOpen={this.state.showSuccessModal}
          toggle={() => this.setState({ showSuccessModal: false })}
        >
          <div className="modal-header">
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={this.closeModal}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="text-center">
              <i className="ni ni-check-bold ni-3x text-success" />
              <h4 className="heading mt-4">¡Solicitud de cierre enviada!</h4>
              <p>
                Se ha enviado la solicitud para cerrar tu fondo dinámico.
                <br />
                ·
                <br />
                Una vez confirmado el cierre de fondo, recibirás una
                notificación y veras reflejado el monto de tu inversión y tus
                rendimientos generados en la sección de "Saldo disponible".
              </p>
            </div>
          </div>
          <div className="modal-footer justify-content-center">
            <Button
              className="text-white"
              color="success"
              data-dismiss="modal"
              type="button"
              onClick={this.closeModal}
            >
              Continuar
            </Button>
          </div>
        </Modal>

        <Modal
          className="modal-dialog-centered"
          isOpen={this.state.showConfirmationModal}
          toggle={() => this.setState({ showConfirmationModal: false })}
        >
          <div className="modal-header">
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={this.closeModal}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="text-center">
              <i className="fa fa-exclamation-triangle ni-3x text-danger" />
              <h4 className="heading mt-4">Solicitud de cierre</h4>
              <p>
                ¿Estas seguro que deseas solicitar el cierre del fondo?
                <br />
                ·
                <br />
                Al cerrar un fondo Dinámico, se te regresa tu monto de inversión
                y los rendimientos generados hasta la fecha de cierre. Una vez
                cerrado el fondo, tu saldo se vera reflejado dentro de 48 a 96
                horas.
              </p>
            </div>
          </div>
          <div className="modal-footer">
            <Button
              outline
              color="default"
              data-dismiss="modal"
              type="button"
              onClick={this.closeModal}
            >
              Cancelar
            </Button>
            <Button
              className="text-white ml-auto"
              color="danger"
              data-dismiss="modal"
              type="button"
              onClick={this.sendCloseFund}
            >
              Cerrar Fondo
            </Button>
          </div>
        </Modal>

        {this.state.showSuccessWithdrawModal && (
          <Modal
            className="modal-dialog-centered"
            isOpen={this.state.showSuccessWithdrawModal}
            toggle={() => {
              this.props.updateDashboard();
              this.setState({
                showSuccessWithdrawModal: false,
              });
              this.props.closeModal();
            }}
          >
            <div className="modal-header">
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  this.props.updateDashboard();
                  this.setState({
                    showSuccessWithdrawModal: false,
                  });
                  this.props.closeModal();
                }}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="text-center">
                <i className="ni ni-check-bold ni-3x text-success" />
                <h4 className="heading mt-4">
                  ¡Solicitud de retiro de inversion realizada!
                </h4>
                <p>
                  Se ha realizado el retiro de{" "}
                  <strong>
                    $
                    {parseFloat(this.state.withdrawAmount)
                      .toFixed(2)
                      .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                  </strong>{" "}
                  de tu inversion en el fondo. .
                  <br />
                  ·
                  <br />
                  Esta cantidad ya se encuentra reflejada en tu Saldo
                  disponible, puedes utilizarlo para invertir en alguno de
                  nuestros fondos, o retirarlo a tu cuenta bancaria si asi lo
                  decides.
                </p>
              </div>
            </div>
            <div className="modal-footer justify-content-center">
              <Button
                className="text-white"
                color="success"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  this.props.updateDashboard();
                  this.setState({
                    showSuccessWithdrawModal: false,
                  });
                  this.props.closeModal();
                }}
              >
                Continuar
              </Button>
            </div>
          </Modal>
        )}
      </>
    );
  }
}
DetailsUserFund.contextType = SessionContext;

export default DetailsUserFund;
