import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardText,
  Col,
  Container,
  Row,
  UncontrolledTooltip,
} from "reactstrap";

import { CopyToClipboard } from "react-copy-to-clipboard";
import Header from "components/Headers/Header.js";
import React from "react";
import { SessionContext } from "contexts/SessionContext";

class AddMoney extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      copiedText: false,
      copiedText2: false,
    };
    this.bankAccountNumber = process.env.REACT_APP_BANK_CLABE;
    this.bankAccountBeneficiary = process.env.REACT_APP_BANK_BENEFICIARY;
    this.bankName = process.env.REACT_APP_BANK_NAME;
    this.bankAccountNumberBBVA = process.env.REACT_APP_BANK_CIE_BBVA;
  }

  render() {
    return (
      <>
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            <Col>
              <Card className="shadow border-0">
                <CardHeader className="bg-transparent">
                  <Row className="align-items-center">
                    <Col md="9">
                      <h1 className="mb-0 mt-1 pb-1">
                        Agregar saldo a tu cuenta
                      </h1>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  {/* <Row>
                    <Col className="text-center">
                      <h2>
                        Agregar saldo desde BBVA:{" "}
                        <img
                          src="https://1000marcas.net/wp-content/uploads/2019/12/BBVA-logo.png"
                          alt="bbva-logo"
                          width="100px"
                          className="mb-2 ml-3"
                        ></img>
                      </h2>
                      <CardText className="mt-0">
                        Para agregar saldo a tu cuenta de Inversa,{" "}
                        <b>desde BBVA</b>, ingresa a tu app mobil o desde la
                        plataforma web, entra a la seccion de Pago de Servicios
                        e ingresa el numero de convenio y numero de referencia:
                      </CardText>
                    </Col>
                  </Row>
                  <Row className="justify-content-center">
                    <Col sm="0" xs="0" md="3"></Col>
                    <Col>
                      <Row className="pt-3 justify-content-start">
                        <Col
                          md={{ size: "auto" }}
                          xs={{ size: "12" }}
                          sm={{ size: "auto" }}
                        >
                          <h3>No. de convenio:</h3>
                        </Col>
                        <Col
                          md={{ size: "auto" }}
                          xs={{ size: "auto" }}
                          sm={{ size: "auto" }}
                        >
                          {" "}
                          <div className="border rounded py-1 px-2 text-center">
                            <h3 className="m-0">
                              {this.bankAccountNumberBBVA}
                            </h3>
                          </div>
                        </Col>
                        <Col
                          md={{ size: "auto" }}
                          xs={{ size: "auto" }}
                          sm={{ size: "auto" }}
                          className="p-0"
                        >
                          <CopyToClipboard
                            text={this.bankAccountNumberBBVA}
                            onCopy={() => this.setState({ copiedText: true })}
                          >
                            <Button
                              className="btn-icon btn-2 btn btn-primary py-1 px-2"
                              color="primary"
                              type="button"
                              id="copy-toolptip"
                            >
                              <i className="far fa-copy" />
                            </Button>
                          </CopyToClipboard>
                          <UncontrolledTooltip
                            delay={0}
                            trigger="hover focus"
                            target="copy-toolptip"
                          >
                            {this.state.copiedText
                              ? "Copiado"
                              : "Copiar al portapapeles"}
                          </UncontrolledTooltip>
                        </Col>
                      </Row>
                      <Row className="pt-3 justify-content-start">
                        <Col
                          md={{ size: "auto" }}
                          xs={{ size: "12" }}
                          sm={{ size: "auto" }}
                        >
                          <h3>No. de referencia:</h3>
                        </Col>
                        <Col
                          md={{ size: "auto" }}
                          xs={{ size: "auto" }}
                          sm={{ size: "auto" }}
                        >
                          {" "}
                          <div className="border rounded py-1 px-2 text-center">
                            <h3 className="m-0">
                              {this.context.session.reference_number}
                            </h3>
                          </div>
                        </Col>
                        <Col
                          md={{ size: "auto" }}
                          xs={{ size: "auto" }}
                          sm={{ size: "auto" }}
                          className="p-0"
                        >
                          <CopyToClipboard
                            text={this.context.session.reference_number}
                            onCopy={() => this.setState({ copiedText2: true })}
                          >
                            <Button
                              className="btn-icon btn-2 btn btn-primary py-1 px-2"
                              color="primary"
                              type="button"
                              id="copy-toolptip"
                            >
                              <i className="far fa-copy" />
                            </Button>
                          </CopyToClipboard>
                          <UncontrolledTooltip
                            delay={0}
                            trigger="hover focus"
                            target="copy-toolptip"
                          >
                            {this.state.copiedText2
                              ? "Copiado"
                              : "Copiar al portapapeles"}
                          </UncontrolledTooltip>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <hr></hr> */}
                  <Row className="mt-5">
                    <Col className="text-center">
                      <h2>Agregar saldo a tu cuenta de Inversa:</h2>
                      <CardText className="mt-0">
                        Para agregar saldo a tu cuenta de Inversa{" "}
                        <b>desde cualquier banco</b>, envía un{" "}
                        <b>SPEI o transferencia electrónica</b> desde tu banca
                        por internet utilizando los siguientes datos:
                      </CardText>
                    </Col>
                  </Row>
                  <Row className="justify-content-center">
                    <Col sm="0" xs="0" md="3"></Col>
                    <Col>
                      <Row className="pt-3 justify-content-start">
                        <Col
                          md={{ size: "auto" }}
                          xs={{ size: "12" }}
                          sm={{ size: "auto" }}
                        >
                          <h3>CLABE bancaria:</h3>
                        </Col>
                        <Col
                          md={{ size: "auto" }}
                          xs={{ size: "auto" }}
                          sm={{ size: "auto" }}
                        >
                          {" "}
                          <div className="border rounded py-1 px-2 text-center">
                            <h3 className="m-0">{this.bankAccountNumber}</h3>
                          </div>
                        </Col>
                        <Col
                          md={{ size: "auto" }}
                          xs={{ size: "auto" }}
                          sm={{ size: "auto" }}
                          className="p-0"
                        >
                          <CopyToClipboard
                            text={this.bankAccountNumber}
                            onCopy={() => this.setState({ copiedText: true })}
                          >
                            <Button
                              className="btn-icon btn-2 btn btn-primary py-1 px-2"
                              color="primary"
                              type="button"
                              id="copy-toolptip"
                            >
                              <i className="far fa-copy" />
                            </Button>
                          </CopyToClipboard>
                          <UncontrolledTooltip
                            delay={0}
                            trigger="hover focus"
                            target="copy-toolptip"
                          >
                            {this.state.copiedText
                              ? "Copiado"
                              : "Copiar al portapapeles"}
                          </UncontrolledTooltip>
                        </Col>
                      </Row>
                      <Row className="pt-3 justify-content-start">
                        <Col
                          md={{ size: "auto" }}
                          xs={{ size: "12" }}
                          sm={{ size: "auto" }}
                        >
                          <h3>No. de referencia:</h3>
                        </Col>
                        <Col
                          md={{ size: "auto" }}
                          xs={{ size: "auto" }}
                          sm={{ size: "auto" }}
                        >
                          {" "}
                          <div className="border rounded py-1 px-2 text-center">
                            <h3 className="m-0">
                              {this.context.session.reference_number}
                            </h3>
                          </div>
                        </Col>
                        <Col
                          md={{ size: "auto" }}
                          xs={{ size: "auto" }}
                          sm={{ size: "auto" }}
                          className="p-0"
                        >
                          <CopyToClipboard
                            text={this.context.session.reference_number}
                            onCopy={() => this.setState({ copiedText2: true })}
                          >
                            <Button
                              className="btn-icon btn-2 btn btn-primary py-1 px-2"
                              color="primary"
                              type="button"
                              id="copy-toolptip"
                            >
                              <i className="far fa-copy" />
                            </Button>
                          </CopyToClipboard>
                          <UncontrolledTooltip
                            delay={0}
                            trigger="hover focus"
                            target="copy-toolptip"
                          >
                            {this.state.copiedText2
                              ? "Copiado"
                              : "Copiar al portapapeles"}
                          </UncontrolledTooltip>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className="pt-4 justify-content-center">
                    <Col lg={{ size: "auto" }}>
                      <Row className="mt-2">
                        <Col
                          md={{ size: "auto" }}
                          xs={{ size: "auto" }}
                          sm={{ size: "auto" }}
                        >
                          <h1 className="mb-0">1</h1>
                        </Col>
                        <Col className="my-auto">
                          <p className="mb-0">
                            En la página web o aplicacion móvil de tu banco,
                            selecciona la opción de{" "}
                            <strong>
                              Envio SPEI o Transferencia electrónica
                            </strong>
                            .
                          </p>
                        </Col>
                      </Row>
                      <Row className="mt-2">
                        <Col
                          md={{ size: "auto" }}
                          xs={{ size: "auto" }}
                          sm={{ size: "auto" }}
                        >
                          <h1 className="mb-0">2</h1>
                        </Col>
                        <Col className="my-auto">
                          <p className="mb-0">
                            Ingresa la cuenta <strong>CLABE</strong> destino:{" "}
                            <strong className="h3">
                              {this.bankAccountNumber}.
                            </strong>
                          </p>
                        </Col>
                      </Row>
                      <Row className="mt-2">
                        <Col
                          md={{ size: "auto" }}
                          xs={{ size: "auto" }}
                          sm={{ size: "auto" }}
                        >
                          <h1 className="mb-0">3</h1>
                        </Col>
                        <Col className="my-auto">
                          <p className="mb-0">
                            En el campo{" "}
                            <strong>"Concepto" y/o "Referencia"</strong>,
                            escribe tu número de referencia{" "}
                            <strong className="h3">
                              {this.context.session.reference_number}.
                            </strong>
                          </p>
                        </Col>
                      </Row>
                      <Row className="mt-2">
                        <Col
                          md={{ size: "auto" }}
                          xs={{ size: "auto" }}
                          sm={{ size: "auto" }}
                        >
                          <h1 className="mb-0">4</h1>
                        </Col>
                        <Col className="my-auto">
                          <p className="mb-0">
                            En el campo <strong>"Beneficiario"</strong>, ingresa{" "}
                            <strong className="h3">
                              {this.bankAccountBeneficiary}
                            </strong>
                          </p>
                        </Col>
                      </Row>
                      <Row className="mt-2">
                        <Col
                          md={{ size: "auto" }}
                          xs={{ size: "auto" }}
                          sm={{ size: "auto" }}
                        >
                          <h1 className="mb-0">5</h1>
                        </Col>
                        <Col className="my-auto">
                          <p className="mb-0">
                            En el campo <strong>"Banco"</strong>, selecciona{" "}
                            <strong className="h3">{this.bankName}.</strong>
                          </p>
                        </Col>
                      </Row>
                      <Row className="mt-2">
                        <Col
                          md={{ size: "auto" }}
                          xs={{ size: "auto" }}
                          sm={{ size: "auto" }}
                        >
                          <h1 className="mb-0">6</h1>
                        </Col>
                        <Col className="my-auto">
                          <p className="mb-0">
                            Guarda el <strong>comprobante</strong> de tu
                            transferencia.
                          </p>
                        </Col>
                      </Row>
                      <Row className="mt-2">
                        <Col
                          md={{ size: "auto" }}
                          xs={{ size: "auto" }}
                          sm={{ size: "auto" }}
                        >
                          <h1 className="mb-0">7</h1>
                        </Col>
                        <Col className="my-auto">
                          <p className="mb-0">
                            Envía tu <strong>comprobante</strong> por correo
                            electrónico a{" "}
                            <strong className="h3">
                              contacto@inversadigital.mx
                            </strong>
                          </p>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className="pt-5">
                    <Col className="text-center">
                      <small>
                        Cuando hayas transferido saldo a tu cuenta, lo verás
                        reflejado en un máximo de 42 horas en el la sección de
                        "Resumen" en el apartado de "Saldo disponible"
                      </small>
                    </Col>
                  </Row>
                  <Row className="pt-3">
                    <Col className="text-center">
                      <small>
                        Para cualquier duda o aclaración, puedes contactarnos a
                        través de nuestro chat en{" "}
                        <a href="https://www.facebook.com/InversaFinanciera">
                          Facebook
                        </a>{" "}
                        o por correo electrónico a{" "}
                        <a href="mailto:contacto@inversadigital.mx">
                          contacto@inversadigital.mx
                        </a>
                      </small>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}
AddMoney.contextType = SessionContext;

export default AddMoney;
