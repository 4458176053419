export async function getUserBankCards(userId) {
  return fetch(`${process.env.REACT_APP_API_PATH}/user-bank-card/${userId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    credentials: "include",
  })
    .then((res) => {
      const response = res.json();
      return response;
    })
    .then((response) => {
      if (!response) return false;
      return response;
    })
    .catch((error) => {
      console.log(error);
      return false;
    });
}

export async function removeUserBankCard(userId, cardId) {
  return fetch(`${process.env.REACT_APP_API_PATH}/user-bank-card`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    credentials: "include",
    body: JSON.stringify({
      user_id: userId,
      bank_card_id: cardId,
    }),
  })
    .then((res) => {
      if (res.status !== 200) return false;
      const response = res.json();
      return response;
    })
    .then((response) => {
      if (!response) return false;
      return response;
    })
    .catch((error) => {
      console.log(error);
      return false;
    });
}

export async function addUserBankCard(userId, card) {
  return fetch(`${process.env.REACT_APP_API_PATH}/user-bank-card`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    credentials: "include",
    body: JSON.stringify({
      user_id: userId,
      ...card,
    }),
  })
    .then((res) => {
      if (res.status !== 200) return false;
      const response = res.json();
      return response;
    })
    .then((response) => {
      if (!response) return false;
      return response;
    })
    .catch((error) => {
      console.log(error);
      return false;
    });
}

export async function requestBankCardApproval(userId, bankCardId) {
  return fetch(`${process.env.REACT_APP_API_PATH}/request-bank-card-approval`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    credentials: "include",
    body: JSON.stringify({
      user_id: userId,
      bank_card_id: bankCardId,
    }),
  })
    .then((res) => {
      if (res.status !== 200) return false;
      const response = res.json();
      return response;
    })
    .then((response) => {
      if (!response) return false;
      return response;
    })
    .catch((error) => {
      console.log(error);
      return false;
    });
}
