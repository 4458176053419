import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from "reactstrap";

import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";
import React from "react";
import { SessionContext } from "contexts/SessionContext";
import { sendRestorePasswordEmail } from "services/auth";

class ForgotPassword extends React.Component {
  state = { emailSent: false, email: "", errorEmail: false };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
      authError: false,
    });
  };

  sendEmail = async () => {
    if (!this.state.email) {
      this.setState({ errorEmail: true });
    } else {
      if (!this.state.emailSent) {
        await sendRestorePasswordEmail(this.state.email);
        this.setState({ emailSent: true, errorEmail: false });
      }
    }
  };

  render() {
    return (
      <>
        <Col lg="5" md="7">
          <Card className="bg-secondary shadow border-0">
            <CardBody className="px-lg-5 py-lg-5 text-center">
              <div className="text-center text-muted mb-5">
                <h1>Restaurar contraseña.</h1>
              </div>
              {!this.state.emailSent ? (
                <>
                  <p>Si no recuerdas tu contraseña, puedes recuperarla aquí.</p>
                  <div className="text-center">
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon
                        addonType="prepend"
                        className={`form-control-alternative ${
                          this.state.errorEmail &&
                          "is-invalid border-top border-bottom border-left border-warning text-warning"
                        }`}
                      >
                        <InputGroupText>
                          <i className="ni ni-email-83" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        className={`form-control-alternative ${
                          this.state.errorEmail &&
                          "is-invalid border-top border-bottom border-right border-warning text-warning"
                        }`}
                        placeholder="Email"
                        type="email"
                        autoComplete="new-email"
                        name="email"
                        value={this.state.email}
                        onChange={this.handleChange}
                        required
                      />
                    </InputGroup>
                    <Button
                      className="mt-4"
                      color="primary"
                      type="button"
                      onClick={this.sendEmail}
                    >
                      Continuar
                    </Button>
                  </div>
                </>
              ) : (
                <p>
                  Si existe una cuenta registrada con ese email, recibirás un
                  correo con el proceso para restaurar tu contraseña.
                </p>
              )}
            </CardBody>
            <CardFooter>
              <Row>
                <Col className="text-left" xs="6">
                  <p className="text-muted mb-0 small">
                    <NavLink to="/auth/login" tag={Link}>
                      Iniciar sesión
                    </NavLink>
                  </p>
                </Col>
                <Col className="text-right" xs="6">
                  <p className="text-muted mb-0 small">
                    <NavLink to="/auth/register" tag={Link}>
                      Crear una nueva cuenta.
                    </NavLink>
                  </p>
                </Col>
              </Row>
            </CardFooter>
          </Card>
        </Col>
      </>
    );
  }
}
ForgotPassword.contextType = SessionContext;

export default ForgotPassword;
