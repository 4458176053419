import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardText,
  Col,
  Container,
  ListGroup,
  ListGroupItem,
  Row,
  Badge,
} from "reactstrap";
import { getUserBankCards, removeUserBankCard } from "services/userBankCard";

import AddBankAccountForm from "components/Forms/AddBankAccountForm";
import Header from "components/Headers/Header.js";
import React from "react";
import { SessionContext } from "contexts/SessionContext";
import Spinner from "reactstrap/lib/Spinner";

class BankAccounts extends React.Component {
  state = {
    showAddAccount: false,
    loading: true,
    errorLoading: false,
    cards: null,
  };

  getBankCards = async () => {
    const resCards = await getUserBankCards(this.context.session.id);
    if (!resCards) {
      this.setState({ loading: false, errorLoading: true });
    } else {
      if (resCards.data && resCards.data.length >= 1) {
        this.setState({
          cards: resCards.data,
          loading: false,
          errorLoading: false,
        });
      } else {
        this.setState({
          loading: false,
          cards: null,
          errorLoading: false,
          showAddAccount: true,
        });
      }
    }
  };

  async componentDidMount() {
    this.getBankCards();
  }

  removeCard = async (cardId) => {
    const resCards = await removeUserBankCard(this.context.session.id, cardId);
    if (resCards && resCards.success) {
      this.getBankCards();
    }
  };

  render() {
    return (
      <>
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          {this.state.loading ? (
            <Row>
              <Col>
                <Card className="shadow border-0">
                  <CardHeader className="bg-transparent">
                    <Row className="align-items-center">
                      <Col md="9">
                        <h1 className="mb-0 mt-1 pb-1">-</h1>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Row className="pt-4 justify-content-center">
                      <Col className="text-center">
                        <Spinner style={{ width: "3rem", height: "3rem" }} />{" "}
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          ) : (
            <Row>
              <Col>
                <Card className="shadow border-0">
                  <CardHeader className="bg-transparent">
                    <Row className="align-items-center">
                      <Col md="9">
                        {this.state.showAddAccount ? (
                          <h1 className="mb-0 mt-1 pb-1">
                            Agregrar cuenta bancaria
                          </h1>
                        ) : (
                          <h1 className="mb-0 mt-1 pb-1">Cuentas bancarias</h1>
                        )}
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    {this.state.showAddAccount ? (
                      <AddBankAccountForm
                        hideCancelButton={this.state.cards ? false : true}
                        handleCancel={() => {
                          this.setState({ showAddAccount: false });
                          this.getBankCards();
                        }}
                      />
                    ) : (
                      <>
                        <Row>
                          <Col className="text-center">
                            <CardText className="mt-0">
                              Administra tus cuentas bancarias. Estas cuentas
                              son únicamente para recibir tus retiros de saldo
                              disponible o intereses generados.
                            </CardText>
                          </Col>
                        </Row>

                        <Row className=" pt-4 justify-content-center">
                          <Col md="6">
                            {this.state.cards && (
                              <ListGroup>
                                {this.state.cards.map((card) => (
                                  <ListGroupItem
                                    className="rounded shadow mb-2"
                                    key={card.id}
                                  >
                                    <Row className="justify-content-center">
                                      <Col className="my-auto col-auto">
                                        <i className="fa fa-credit-card fa-2x"></i>
                                      </Col>
                                      <Col className="my-auto">
                                        <div className="border rounded text-center p-1">
                                          <h3 className="m-0 p-1">
                                            **** {card.last_digits}
                                          </h3>
                                        </div>
                                      </Col>
                                      <Col className="my-auto col-auto">
                                        {card.status === 1 ? (
                                          <Button
                                            size="sm"
                                            className="btn-icon btn-2"
                                            color="danger"
                                            type="button"
                                            onClick={() =>
                                              this.removeCard(card.id)
                                            }
                                          >
                                            <i className="fa fa-trash" />
                                          </Button>
                                        ) : (
                                          <Badge
                                            color={
                                              card.status === 3
                                                ? "warning"
                                                : "danger"
                                            }
                                          >
                                            {card.status === 3
                                              ? "Esperando aprobacion"
                                              : "Rechazada"}
                                          </Badge>
                                        )}
                                      </Col>
                                    </Row>
                                  </ListGroupItem>
                                ))}
                              </ListGroup>
                            )}
                            {this.state.cards?.length < 3 && (
                              <Row className="pt-2 justify-content-center">
                                <Col className="text-center">
                                  <Button
                                    color="primary"
                                    type="button"
                                    onClick={() =>
                                      this.setState({ showAddAccount: true })
                                    }
                                  >
                                    Agregar
                                  </Button>
                                </Col>
                              </Row>
                            )}
                          </Col>
                        </Row>
                      </>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </>
    );
  }
}
BankAccounts.contextType = SessionContext;

export default BankAccounts;
