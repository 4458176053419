import {
  Button,
  CardText,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Row,
  FormText,
  Label,
  Spinner,
} from "reactstrap";

import {
  getUserBankCards,
  requestBankCardApproval,
} from "services/userBankCard";
import React from "react";
import { SessionContext } from "contexts/SessionContext";
import { addUserBankCard } from "services/userBankCard";
import { clabe } from "clabe-validator";
import { addFiles } from "services/userFiles";

class AddBankAccountForm extends React.Component {
  state = {
    name: `${this.context.session.name} ${this.context.session.last_name} ${this.context.session.second_last_name}`.toUpperCase(),
    bank: "",
    clabeMessage: "",
    account_number: "",
    bank_account_proof: null,
    bank_account_proof_img: null,
    loading: false,
  };

  handleChange = (event) => {
    if (event.target.name === "name") {
      event.target.value = event.target.value.toUpperCase();
    }
    if (event.target.name === "clabe") {
      const clabeCheck = clabe.validate(event.target.value);
      this.setState({ bank: clabeCheck.ok ? clabeCheck.tag : "" });
    }
    this.setState({
      [event.target.name]: event.target.value,
      errorName: false,
      errorAccountNumber: false,
      errorBank: false,
      errorClabe: false,
      clabeMessage: "",
    });
  };

  handleChangeFile = (event) => {
    // Validate File Size lower than 4MB.
    let file = false;
    if (event.target.files[0]) {
      if ((event.target.files[0].size / 1000000).toFixed(2) <= 4) {
        // Create new file with the correct name.
        file = event.target.files[0];
      }
    } else {
      file = null;
    }
    this.setState({
      [event.target.name]: file,
      [event.target.name + "_img"]: file
        ? URL.createObjectURL(event.target.files[0])
        : null,
    });
  };

  getBankCards = async () => {
    const resCards = await getUserBankCards(this.context.session.id);
    if (!resCards) {
      this.setState({ loading: false, errorLoading: true });
    } else {
      if (resCards.data && resCards.data.length >= 1) {
        this.setState({
          cards: resCards.data,
          loading: false,
          errorLoading: false,
        });
      } else {
        this.setState({
          loading: false,
          cards: null,
          errorLoading: false,
          showAddAccount: true,
        });
      }
    }
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    this.setState({ loading: true });
    const name = this.state.name ? true : false;
    const errorName = !name ? true : false;
    const bank = this.state.bank.length ? true : false;
    const errorAccountNumber = !this.state.account_number.length ? true : false;
    let errorClabe = !bank ? true : false;
    let clabeMessage = "";

    await this.getBankCards();
    if (this.state.cards && this.state.cards.length) {
      if (
        this.state.cards.find(
          (card) =>
            card.last_digits ===
            this.state.clabe.substr(this.state.clabe.length - 4)
        )
      ) {
        errorClabe = true;
        clabeMessage = "Ya cuentas con esta CLABE dada de alta.";
      }
    }

    // Set the file name according to the card.
    const blob = this.state.bank_account_proof;
    const file = new File(
      [blob],
      `bank_account_proof_${
        this.state.cards && this.state.cards.length
          ? this.state.cards.length + 1
          : 1
      }.${blob.name.split(".").pop()}`,
      {
        type: blob.type,
      }
    );
    if (errorName || errorClabe || errorAccountNumber) {
      this.setState({
        errorName,
        errorClabe,
        errorAccountNumber,
        clabeMessage,
      });
    } else {
      const card = {
        name: this.state.name,
        bank: this.state.bank,
        clabe: this.state.clabe,
        account_number: this.state.account_number,
      };
      const resAddCard = await addUserBankCard(this.context.session.id, card);
      if (resAddCard.success) {
        // Add credit card file.
        const addFilesResponse = await addFiles(this.context.session.id, [
          file,
        ]);
        if (addFilesResponse.success) {
          // Send approve request for bank card.
          const idCart = resAddCard.data.find(
            (card) =>
              card.last_digits ===
              this.state.clabe.substr(this.state.clabe.length - 4)
          ).id;
          const requestBankCardApprovalResponse = await requestBankCardApproval(
            this.context.session.id,
            idCart
          );
          if (requestBankCardApprovalResponse.success)
            this.props.handleCancel();
        }
      }
    }
  };

  render() {
    return (
      <Form role="form" onSubmit={this.handleSubmit}>
        <Row className="pb-4">
          <Col className="text-center">
            <CardText className="mt-0">
              Para retirar saldo de tu cuenta de Inversa, es necesario asociar
              una cuenta bancaria a nombre del inversionista.
              <br />
              <small>
                <em>
                  Esta cuenta sera utilizada unicamente para el deposito de
                  saldo disponible o intereses generados.
                </em>
              </small>
            </CardText>
          </Col>
        </Row>
        <Container>
          <Row>
            <Col md="6">
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="example-text-input"
                >
                  Titular de la cuenta *
                </label>
                <Input
                  name="name"
                  onChange={this.handleChange}
                  defaultValue={this.state.name}
                  type="text"
                  className={`form-control ${
                    this.state.errorName &&
                    "is-invalid border border-warning text-warning"
                  }`}
                />
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="example-text-input"
                >
                  Institución bancaria *
                </label>
                <Input
                  disabled
                  value={this.state.bank}
                  name="bank"
                  type="text"
                  className={`form-control ${
                    this.state.errorBank &&
                    "is-invalid border border-warning text-warning"
                  }`}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="example-text-input"
                >
                  CLABE interbancaria *
                </label>
                <Input
                  onChange={this.handleChange}
                  name="clabe"
                  type="text"
                  className={`form-control ${
                    this.state.errorClabe &&
                    "is-invalid border border-warning text-warning"
                  }`}
                />
                <FormText color="danger" className="ml-2">
                  {this.state.clabeMessage}
                </FormText>
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="example-text-input"
                >
                  No. de cuenta *
                </label>
                <Input
                  onChange={this.handleChange}
                  name="account_number"
                  type="text"
                  className={`form-control ${
                    this.state.errorAccountNumber &&
                    "is-invalid border border-warning text-warning"
                  }`}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <FormGroup>
                <Label for="bank_account_proof" className="form-control-label">
                  <i className="fas fa-money-check-alt mr-2"></i>
                  Adjuntar la carátula del <b>estado de cuenta</b> bancario que
                  incluya <b>nombre, CLABE interbancaria y cuenta.</b>
                </Label>
                <Input
                  type="file"
                  name="bank_account_proof"
                  id="bank_account_proof"
                  accept="image/jpg, image/png, image/jpeg, application/pdf, application/vnd.ms-excel"
                  onChange={this.handleChangeFile}
                  className={
                    this.state.bank_account_proof === false
                      ? "border border-warning rounded p-1"
                      : ""
                  }
                />
                <FormText
                  color={
                    this.state.bank_account_proof === false
                      ? "warning"
                      : "muted"
                  }
                >
                  Formatos aceptados .jpg .jpeg .png .pdf <br />
                  Peso máximo 4 MB.
                </FormText>
              </FormGroup>
              <div>
                {this.state.bank_account_proof?.type === "application/pdf" ? (
                  <embed
                    src={this.state.bank_account_proof_img}
                    width="200"
                    height="auto"
                  ></embed>
                ) : (
                  <img
                    src={this.state.bank_account_proof_img}
                    width="200"
                    height="auto"
                    className="mb-3"
                    alt="bank_account_proof_img"
                    hidden={!this.state.bank_account_proof_img}
                  />
                )}
              </div>
            </Col>
          </Row>
          <Row className="pt-2 justify-content-center">
            <Col className="text-center">
              <Button
                className="btn-icon btn-3"
                color="danger"
                type="button"
                onClick={this.props.handleCancel}
                outline
                hidden={this.props.hideCancelButton | false}
              >
                Cancelar
              </Button>
              {this.state.loading ? (
                <Spinner color="dark" />
              ) : (
                <Button
                  className="btn-icon btn-3"
                  color="primary"
                  type="submit"
                  disabled={
                    !this.state.clabe ||
                    !this.state.name ||
                    !this.state.bank ||
                    !this.state.bank_account_proof ||
                    !this.state.account_number
                  }
                >
                  Agregar
                </Button>
              )}
            </Col>
          </Row>
        </Container>
        <Row className="pt-5">
          <Col className="text-center">
            <small>
              Una vez dada de alta tu cuenta bancaria, podrás realizar tus
              retiros a esta cuenta.
            </small>
          </Col>
        </Row>
      </Form>
    );
  }
}
AddBankAccountForm.contextType = SessionContext;

export default AddBankAccountForm;
