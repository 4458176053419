export async function getUserBalance(userId) {
  return fetch(`${process.env.REACT_APP_API_PATH}/user-balance/${userId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    credentials: "include",
  })
    .then((res) => {
      if (res.status !== 200) return false;
      const response = res.json();
      return response;
    })
    .then((response) => {
      if (!response) return false;
      if (response.success && response.data) return response;
      return false;
    })
    .catch((error) => {
      console.log(error);
      return false;
    });
}

export async function whithdrawUserBalance(userId, amount, cardId) {
  return fetch(`${process.env.REACT_APP_API_PATH}/user-balance/withdraw`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    credentials: "include",
    body: JSON.stringify({
      user_id: userId,
      amount,
      bank_card_id: cardId,
    }),
  })
    .then((res) => {
      if (res.status !== 200) return false;
      const response = res.json();
      return response;
    })
    .then((response) => {
      if (!response) return false;
      return response;
    })
    .catch((error) => {
      console.log(error);
      return false;
    });
}
