import {
  Alert,
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from "reactstrap";
import { SessionContext, setSessionCookie } from "contexts/SessionContext";

import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";
import React from "react";
import { login } from "services/auth";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      authError: false,
      disabledButtons: false,
    };
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
      authError: false,
    });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    this.setState({ authError: false, disabledButtons: true });
    const authResponse = await login(this.state.email, this.state.password);
    if (!authResponse)
      this.setState({ authError: true, disabledButtons: false });
    else {
      if (authResponse.token && authResponse.user) {
        await setSessionCookie(authResponse.user);
        this.context.checkSession();
        this.props.history.push("/dashboard");
      }
    }
  };

  render() {
    return (
      <>
        <Col lg="5" md="7">
          <Card className="bg-secondary shadow border-0">
            <CardBody className="px-lg-5 py-lg-5">
              <div className="text-center text-muted mb-5">
                <h1>Inicia sesión</h1>
              </div>
              {this.state.authError && (
                <Alert color="danger" className="mt--4 mb-4">
                  <span className="alert-inner--icon">
                    <i className="fa fa-lock m-2" />
                  </span>{" "}
                  Correo o Contraseña incorrectos.
                </Alert>
              )}
              <Form role="form" onSubmit={this.handleSubmit}>
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      className="form-control-alternative"
                      placeholder="Email"
                      type="email"
                      autoComplete="email"
                      name="email"
                      value={this.state.email}
                      onChange={this.handleChange}
                      disabled={this.state.disabledButtons}
                      required
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      className="form-control-alternative"
                      placeholder="Contraseña"
                      type="password"
                      autoComplete="password"
                      name="password"
                      value={this.state.password}
                      onChange={this.handleChange}
                      disabled={this.state.disabledButtons}
                      required
                    />
                  </InputGroup>
                </FormGroup>
                <div className="text-center">
                  <Button
                    className="mt-4"
                    color="primary"
                    type="submit"
                    disabled={this.state.disabledButtons}
                  >
                    Iniciar sesión
                  </Button>
                </div>
              </Form>
            </CardBody>
            <CardFooter>
              <Row>
                <Col className="text-left" xs="6">
                  <p className="text-muted mb-0 small">
                    <NavLink to="/auth/forgot" tag={Link}>
                      ¿Olvidaste tu contraseña?
                    </NavLink>
                  </p>
                </Col>
                <Col className="text-right" xs="6">
                  <p className="text-muted mb-0 small">
                    <NavLink to="/auth/register" tag={Link}>
                      Crear una nueva cuenta
                    </NavLink>
                  </p>
                </Col>
              </Row>
            </CardFooter>
          </Card>
        </Col>
      </>
    );
  }
}
Login.contextType = SessionContext;

export default Login;
